import cryptoRandomString from "crypto-random-string";
import GameContract, { TransactionAction } from "../../../model/contract/GameContract";
import EOSClient, { prepareTableRequest } from "../../../model/RPCClient";
import { RpcError } from 'eosjs';
import { getRandomInt } from "../../../model/Tools";


const ACTION_LVL_UP = 'lvlup';
const PAY_IN_GAME = 'payingame';

export const PRICE_REPAIR_WEAPON = 10800;
export const PRICE_REPAIR_JEWELRY = 4200;
export const PRICE_FOOD_COMMON = 2800;

export interface NFTOnGameEC {
    asset_id: string,
    storer: string,
    name_pa: string,
    rarity: number,
    is_in_slot: number,
    lvl: number,
    steps: number,
    stake_count: number,
    timer: number,
    finalnumber: number
    weapon: number,
    whomgrent: number,
    timerrent: number,
    rentlvl: number,
    percprof: number,
    energy: number,
    save: number,
    clas: string,
    element: string
}

interface User {
    allsum: number,
    balance: number,
    inrent: number,
    max_slots: number,
    maxrank: number,
    refnum: number,
    slot_counts: any[],
    username: string,
}

export interface Weapon {
    asset_id: string,
    element: string,
    energy: number
    is_in_slot: number
    panda_id: string,
    rarity: number,
    storer: string
}

export interface Jewelry {
    asset_id: string,
    element: string,
    energy: number,
    is_in_slot: number,
    panda_id: string,
    rarity: number
    storer: string,
    tmpl: number
}



/**
 
Mise à jour

Buy Eat
https://wax.bloks.io/transaction/4483ad63d9e0bd51c23543ac7c4b170744192ff8047764924c89cb0391378adb 

Eat 
https://wax.bloks.io/transaction/b087693df4815aad8523094d0c24e407de002a806938ae815fa47dd38ff01fea

*/



/**
 * https://wax.bloks.io/account/nftpandawofg
 * 
 * levelup
 * https://wax.bloks.io/transaction/21ae832f85bbf08d7dcb0a5c0ca83c1bdeb9e768e6f419b1a6caf01f61a39949
 * 

 * 
 * assign weapon
 * https://wax.bloks.io/transaction/cb122ab509f3a41c55fcff4389d6c39ed502776f3e15a7fdb497cc25ea7f47a5
 * https://wax.bloks.io/transaction/5f21edfc4d4f1bc648fff852925d3d8b9a729688dd63dfe2357a53466fc87d71
 *    asset_ids:
      1099590146217
      from: qwnho.wam
      memo: sendweapon 1099545850052 for panda
      to: nftpandawofg
 * 
enery weapon
https://wax.bloks.io/transaction/308f0faa8f76e3645f61d337a7cb42a90f6457b9091672939c0ca7c4669287a0


swap token
https://wax.bloks.io/transaction/ae75a8f70f0e6c16a2b770af49bb0d913c08e8847579b31b115a884eae4f1d24

buy weapon part
https://wax.bloks.io/transaction/065e588800e16ae933b2a3a4dd1c7d89a268b6ae20209efc05d2391e5efe3f37

stack token 
https://wax.bloks.io/transaction/05ed046f904efafb3b0c965117e428f52773864133dcab2ab88202764abfc15e


Nouvelle aventures

Bamboo x1
https://wax.bloks.io/transaction/8af035deb24d1c528e40b29ef8ab95228a61b31157fef79555c553afe5a49079

Resources x1
https://wax.bloks.io/transaction/cc3dc3a787db672b7b8a61729190878c8e4b761164ec5e06bcbbb78e9dbef36f


Stones x1
https://wax.bloks.io/transaction/9fb8722587e48d22859cbc3848d72fc402cfe4d80bbfa5cae09eed22293e3349

Bamboo x2
https://wax.bloks.io/transaction/c4a1bfc9e86049321a9334ccbe15b61610ec0f20524d6c5fa0cc800b8f31cf54

 */

interface LevelUpStakeAction {
    asset_id: string,
    bam: string,
}

export default class NftPandaContract extends GameContract {

    static NAME = 'nftpandawofg';
    static CONTRACT_NAME = 'nftpandawofg';
    static COLLECTION_NAME = 'nftpandawaxp'
    static TOKEN_CONTRACT = 'nftpandabamb'

    protected _user: User;
    protected _slots: Map<string, NFTOnGameEC>;
    private _ranks = [
        { "name": "Rookie", "level": 0 },
        { "name": "Officer Cadet", "level": 8 },
        { "name": "Second Lieutenant", "level": 13 },
        { "name": "Lieutenant", "level": 21 },
        { "name": "Captain", "level": 34 },
        { "name": "Major", "level": 55 },
        { "name": "Lieutenant-Colonel", "level": 89 },
        { "name": "Colonel", "level": 144 },
        { "name": "Brigadier", "level": 233 },
        { "name": "Major-General", "level": 377 },
        { "name": "Lieutenant-General", "level": 610 },
        { "name": "General", "level": 987 },
        { "name": "Legend", "level": 1597 },
    ];
    private _foods: number[];
    private _weapons: Weapon[];
    private _jewerly: Jewelry[];
    private _stakeActions: LevelUpStakeAction[];

    constructor(client: EOSClient) {
        super(client);
        this._name = NftPandaContract.NAME;
        this._contractName = NftPandaContract.CONTRACT_NAME;
        this._tokenContract = NftPandaContract.TOKEN_CONTRACT;
        this._collectionName = NftPandaContract.COLLECTION_NAME;
        this._defaultSymbol = 'BAM';
        this._foods = [];
        this._weapons = [];
        this._jewerly = [];
        this._stakeActions = [];

        this._user = {
            allsum: 0, balance: 0, inrent: 0, max_slots: 0, maxrank: 0, refnum: 0, slot_counts: [], username: ''
        }

        this._slots = new Map();

    }

    public loadAssets = async () => {

        await this.getPlayerSlots();

        await this.loadFoodAssets();

        await this.loadWeapons();
        
        await this.loadJewerly();

        await this.getRunningAdventure();

        await super.loadAssets();

        return this;
    }

    public updateAssets = async () => {

        await this.getPlayerSlots();

        await this.loadFoodAssets();

        await this.loadWeapons();

        await this.loadJewerly();
        
        await this.getRunningAdventure();

        await super.updateAssets();

        return this;

    }

    public async loadFoodAssets() {
        /*
        const atomic = new AtomicAssetClient();
        const result = await atomic.loadAssets({
          collection_name: NftPandaContract.COLLECTION_NAME,
          schema_name: 'food',
          owner: this._client.userAccount
        })
        this._foods = result;
        */
        const request = prepareTableRequest({
            code: this._contractName,
            scope: this._contractName,
            index_position: 1,
            table: 'eatable',
            lower_bound: this._client.userAccount,
            upper_bound: this._client.userAccount,
        })
        const result = await this.requestTable(request);
        // console.log(result);
        this._foods = result!['rows'][0]['eat_count'];
        // console.log(this._foods);
    }

    public async loadJewerly() {
        const request = prepareTableRequest({
            code: this._contractName,
            scope: this._contractName,
            table: 'nftjew',
            index_position: 2,
            key_type: "i64",
            lower_bound: this._client.userAccount,
            upper_bound: this._client.userAccount,
        })
        const result = await this.requestTable(request);
        this._jewerly = result!['rows'];
    }

    public async loadWeapons() {
        const request = prepareTableRequest({
            code: this._contractName,
            scope: this._contractName,
            table: 'nftweapons',
            index_position: 2,
            key_type: "i64",
            lower_bound: this._client.userAccount,
            upper_bound: this._client.userAccount,
        })
        const result = await this.requestTable(request);
        this._weapons = result!['rows'];
    }

    public getWeapon(assetId: string) {
        for (let weapon of this._weapons) {
            if (weapon.panda_id === assetId) return weapon;
        }
        return undefined;
    }

    public getJewerly(assetId: string) {
        for (let jewerly of this._jewerly) {
            if (jewerly.panda_id === assetId) return jewerly;
        }
        return undefined;
    }
    

    public getInternalBalance() {
        return this._user.allsum;
    }

    public async getPlayerSlots() {
        const client = this._client;
        try {
            const results = await client.rpc.get_table_rows({
                json: true,
                code: NftPandaContract.CONTRACT_NAME,
                scope: NftPandaContract.CONTRACT_NAME,
                table: 'usersnew',
                lower_bound: client.userAccount,
                upper_bound: client.userAccount,
                limit: 10,
                reverse: false,
                show_payer: false
            });
            const rows = results['rows'];
            this._user = rows[0];
            this._tokens.set('BAM-IG', { symbol: 'BAM-IG', value: this._user.allsum / 10000 });
        }
        catch (e) {
            console.error(e);
        }
    }

    public async getRunningAdventure() {
        const client = this._client;
        try {
            const results = await client.rpc.get_table_rows({
                json: true,
                code: NftPandaContract.CONTRACT_NAME,
                scope: NftPandaContract.CONTRACT_NAME,
                table: 'nftsongamec',
                key_type: "i64",
                index_position: 2,
                lower_bound: client.userAccount,
                upper_bound: client.userAccount,
                limit: -1,
                reverse: true,
                show_payer: false
            });
            const rows = results['rows'];
            rows.forEach((row) => {
                const slot: NFTOnGameEC = row;
                if (slot.is_in_slot) {
                    this._slots.set(slot.asset_id, slot);
                }
            })
        }
        catch (e) {
            console.error(e);
        }
    }

    public getTimeleft(slot: NFTOnGameEC): number {
        const t = slot.timer - new Date().getTime() / 1000;
        return Math.round(t);
    }

    public getNextStep(lvl: number) {
        return this._ranks[lvl + 1];
    }

    public createEatAction(panda_id: string, foodIndex: number) {
        /*
            asspanda: 1099545850052
            eatrar: 0
            storer: qwnho.wam
        */
        const action = this.createGameActions(
            NftPandaContract.NAME,
            this._client.userAccount,
            'pandaeating',
            {
                storer: this._client.userAccount,
                eatrar: foodIndex,
                asspanda: panda_id,
            }
        );

        return action;
    }

    public createLevelUpAction(panda_id: string) {
        return this.createGameActions(
            NftPandaContract.NAME,
            this._client.userAccount,
            ACTION_LVL_UP,
            {
                asset_id: panda_id,
                username: this._client.userAccount
            }
        )
    }

    public createRepairJewelryAction(jewelry_id:string, price:number) {
        // https://wax.bloks.io/transaction/fdcc76ecae40a4ffb7e9fe9b007fcb8dd13d11ab16fd106fed83ca9f26aa07ba
        /*
                bamnum: 4200
                ipfshash: addenergyjew 1099708047425
                storer: qwnho.wam
        */
        const memo = `addenergyjew ${jewelry_id} `
        return this.createGameActions(
            NftPandaContract.NAME,
            this._client.userAccount,
            PAY_IN_GAME,
            {
                bamnum: price,
                ipfshash: memo,
                storer: this._client.userAccount
            }
        )
    }

    public createRepairWeaponAction(weapon_id:string, price: number) {
        /*
          // https://wax.bloks.io/transaction/ba80de0863852935dfe6478bfe497fb9862630331d3d3e87ab9bad51398d59d5
          bamnum: 10800
          ipfshash: addenergyweapon 1099590405722
          storer: qwnho.wam
        */
        const memo = `addenergyweapon ${weapon_id} `
        return this.createGameActions(
            NftPandaContract.NAME,
            this._client.userAccount,
            PAY_IN_GAME,
            {
                bamnum: price,
                ipfshash: memo,
                storer: this._client.userAccount
            }
        )
    }

    public createBuyFoodAction(foodType: string, price:number, quantity: number) {
        const memo = `buyeat ${foodType} ${quantity} `
        return this.createGameActions(
            NftPandaContract.NAME,
            this._client.userAccount,
            PAY_IN_GAME,
            {
                bamnum: price * quantity,
                ipfshash: memo,
                storer: this._client.userAccount
            }
        )
    }

    public createAdventureAction(panda_id: string) {
        const signValue = cryptoRandomString({ length: 14, type: 'numeric' });
        const adventures = [{ name: 'bamboo', pct: 90, duration:4 }, { name: 'resources', pct: 5, duration:6 }, { name: 'stones', pct: 5, duration: 6 }];
        const random = getRandomInt(0, 100);
        let selectedAdventure = {name: '', pct:0, duration:0};
        let currentPct = 0;
        adventures.forEach((a) => {
            currentPct += a.pct;
            if (random <= currentPct && selectedAdventure.name === "") {
                selectedAdventure = a;
            }
        })

        console.log(`Selected adventure random=${random} selected:${selectedAdventure}`);

        return this.createGameActions(
            NftPandaContract.NAME,
            this._client.userAccount,
            'multiadv',
            {
                pandaid: [panda_id],
                duration: selectedAdventure.duration,
                storer: this._client.userAccount,
                typeadv: selectedAdventure.name
            }
        )
    }

    public get foods() {
        return this._foods;
    }

    protected watchJewerlyEnergy() {
        // action to repair
        // https://wax.bloks.io/transaction/756a2c9f958b528bc638b078b41b12aece26de8e6ca02bc36dd13c3bbbafa211
        // check if weapon need to be repared
        const actions: TransactionAction[] = [];
        this._jewerly.forEach((j) => {

            const energy = j.energy;
            // console.log(`weapon ${w.asset_id}  => enery ${energy}`);
            if (energy === 0) {
                console.warn(`Must repair Jewerly !!! j:${j.asset_id} p:${j.panda_id}`);
                // check if you have BAM
                const internalBalance = this.getInternalBalance();
                if (internalBalance > PRICE_REPAIR_JEWELRY) {
                    const repairAction = this.createRepairJewelryAction(j.asset_id, PRICE_REPAIR_JEWELRY);
                    actions.push(repairAction);
                }
                else {
                    console.error(`No BAM for repair Jewerly ${j.asset_id}`);
                }
            }
        });

        return actions;
    }

    protected watchWeaponEnergy() {
        // check if weapon need to be repared
        const actions: TransactionAction[] = [];
        this._weapons.forEach((w) => {

            const energy = w.energy;
            // console.log(`weapon ${w.asset_id}  => enery ${energy}`);
            if (energy === 0) {
                console.warn(`Must repair Weapons !!! w:${w.asset_id} p:${w.panda_id}`);
                // check if you have BAM
                const internalBalance = this.getInternalBalance();
                if (internalBalance > PRICE_REPAIR_WEAPON) {
                    const repairAction = this.createRepairWeaponAction(w.asset_id, PRICE_REPAIR_WEAPON);
                    actions.push(repairAction);
                }
                else {
                    console.error(`No BAM for repair Weapon ${w.asset_id}`);
                }
            }
        });

        return actions;
    }

    protected getFood(): number {
        for (let idx in this._foods) {
            if (this._foods[idx] !== 0) {
                return parseInt(idx);
            }
        }
        return -1;
    }

    public getNumFoods(): number {
        const numFoods = this._foods.reduce((prev, curr, idx) => {
            return prev + curr;
        })
        return numFoods;
    }

    protected watchSlots() {
        const actions: TransactionAction[] = [];
        const slots = Array.from(this._slots.values());
        for (let slot of slots) {
            // check if we need to eat
            if (slot.energy <= 2000) {
                console.warn(`Panda need to eat ${slot.asset_id}`);
                const foodIndex = this.getFood();
                if (foodIndex === -1) {
                    console.warn('no food found must buy food first !');
                    const buyAction = this.createLockedTokenAction(this.createBuyFoodAction('common', PRICE_FOOD_COMMON, 5));
                    if (buyAction) {
                        actions.push(buyAction);
                    }
                }
                else {
                    const action = this.createEatAction(slot.asset_id, foodIndex);
                    actions.push(action);
                }            
            }
            else {
                const nextStep = this._ranks[slot.lvl + 1];
                // console.log(nextStep);
                const timeLeft = this.getTimeleft(slot);
                if (timeLeft < 0) {
                    if (slot.steps >= nextStep.level) {
                        console.log(`Panda level up !! ${slot.asset_id} to lvl: ${slot.lvl + 1} rank:${nextStep.name}`);
                        if (this._stakeActions.length !== 0) {
                            console.warn(`This panda need to add stack`);
                            const stakeAction = this._stakeActions.pop()!;
                            if (stakeAction) {
                                const buyAction = this.createBuyTokenAction(this._tokenContract, this._client.userAccount, 'nftpandawofg', stakeAction?.bam, stakeAction?.asset_id);
                                if (buyAction) {
                                    actions.push(buyAction);
                                }
                            }
                        }
                        else {
                            actions.push(this.createLevelUpAction(slot.asset_id));
                        }
                    }
                    else {
                        console.log(`Start adventure for panda ${slot.asset_id}`);
                        actions.push(this.createAdventureAction(slot.asset_id));
                    }
                }
            }
        }
        return actions;
    }

    public async autoActions(): Promise<TransactionAction[]> {

        await this.updateAssets();

        let actions: TransactionAction[] = [];

        actions = this.watchWeaponEnergy();
        if (actions.length !== 0) return actions;

        actions = this.watchJewerlyEnergy();
        if (actions.length !== 0) return actions;

        actions = this.watchSlots();

        return actions;
    }

    public get slots() {
        return this._slots;
    }

    public handleRpcError(action: TransactionAction, error: RpcError) {
        console.error(`${this._name} get error = ${error.message}`);

        switch (action.name) {
            case ACTION_LVL_UP:
                return this.handleLevelUpError(action, error);
        }

        //  assertion failure with message: Oops, but you can't rank up, because you needed to have staked 23.58 bam, and you must add 9.9 bam
        return action;
    }

    private handleLevelUpError(action: TransactionAction, error: RpcError) {
        const regex = /and you must add (.*) bam/gm;
        const result = regex.exec(error.message);
        if (result && result.length === 2) {
            const bamToStake = parseFloat(result[1]);
            const assetId = action.data['asset_id'] as string;
            console.warn(`Myst stack ${bamToStake.toFixed(4)} BAM on asset ${assetId}`);
            this._stakeActions.push({
                asset_id: assetId,
                bam: `${bamToStake.toFixed(4)} BAM`
            });
        }
        return null;
    }

}


/*

Adventures 

classic 
https://wax.bloks.io/transaction/c8fe2938eca45c1481ade81635d203a9e5aec9492ee1a81afbcc15a0a495af79

stone
https://wax.bloks.io/transaction/31c3adac3d33100f66f93d5ed3cb1768a8ac79c3e55bb19ae1e12f114e14cb17

wood
https://wax.bloks.io/transaction/7ffc5696810b51098cf778fcff81ab4c50974864f198f13caafc2cae3254a3bd


nftpandawofg - login
partner: 
signing_value: 79267724742015
username: qwnho.wam

nftpandawofg - addtoslot
asset_id: 1099569700041
number_slot: 0
username: qwnho.wam


Adventure
nftpandawofg - printrand
assoc_id: 1099569700041
signing_value: 60798765969097
username: qwnho.wam

const rewardTrans = {
    actions: [{
        account: 'nftpandawofg',
        name: 'printrand',
        authorization: [{
          actor: '', // use account that was logged in
          permission: 'active',
        }],
        data: {
            username: '',
            assoc_id: '',
            signing_value: ''
        },
    }],
}
    async sendPandaToAdventures(assoc_id, slotNumber) {
        const number = cryptoRandomString({length: 14, type: 'numeric'});
        const userName = this.props.ual.activeUser.accountName;
        const activeUser = this.props.ual.activeUser;

        var loadingSlot = document.getElementsByTagName("body")[0];
        loadingSlot.classList.add("loading");

        rewardTrans.actions[0].authorization[0].actor = userName
        rewardTrans.actions[0].data.username = userName
        rewardTrans.actions[0].data.assoc_id = assoc_id
        rewardTrans.actions[0].data.signing_value = number;


if(localStorage.getItem('signing_value') == null) {
  const number = cryptoRandomString({length: 14, type: 'numeric'});
  localStorage.setItem('signing_value', number); 
}
https://www.npmjs.com/package/crypto-random-string

*/