import { AnnotationsMap, makeObservable, observable, runInAction } from "mobx";
import GameContract, { TransactionAction } from "./contract/GameContract";
import { getRandomInt } from "./Tools";
import UserStore from "./UserStore";
import { RpcError} from 'eosjs';

export default class GameBot {

  private _mainLoopId: NodeJS.Timeout|undefined;
  private _lastUpdate: Date;
  private _loopTimeMs:number;
  private _isReady:boolean;
  private _isRunning:boolean;
  private _game:GameContract;
  private _actions: TransactionAction[];
  private _actionRunning: boolean;
  private _retryCount: number;

  private _errorActions:string[];
  private _isCPULock:boolean;
  private _cpuLimit:number;
  private _lastActionSend: TransactionAction|null;

  constructor(game:GameContract) {
    
    this._lastUpdate = new Date();
    this._loopTimeMs = 30 * 1000;
    this._isReady = false;
    this._isRunning = false;
    this._game = game;
    this._actions = [];
    this._actionRunning = false;
    this._retryCount = 0;

    this._isCPULock = false;
    this._errorActions = [];
    this._lastActionSend = null;
    // do not do action if CPU is gretter than 
    this._cpuLimit = 0.98;

    makeObservable(this, {
      _lastUpdate: observable,
      _isReady: observable,
      _isRunning: observable,
    } as AnnotationsMap<this, string>);

  }

  public get game() {
    return this._game;
  }

  public async init() {
    this.stopWatch();
    this._actions = [];
    this._actionRunning = false;
    this._retryCount = 0;
    await this._game.loadAssets();
    runInAction(()=> {
      this._isReady = true;
    })
  }

  public get isRunning(): boolean {
    return this._isRunning;
  }

  public get isReady():boolean {
    return this._isReady;
  }

  public async startWatch() {
    if (this._mainLoopId) {
      clearInterval(this._mainLoopId);
    }
    this._loopTimeMs = getRandomInt(0,1000) + UserStore.getInstance().settings.botLoopInSeconds * 1000;
    this._mainLoopId = setInterval(() => this.mainLoop(), this._loopTimeMs);
    runInAction(() => {
      this._isRunning = true;
    })
  }

  protected updateActionRetry() {
    console.warn(`Action is running (${this._retryCount})`);
    this._retryCount++;
    if (this._retryCount > 10) {
      this._retryCount = 0;
      const removedAction = this._actions.shift();
      console.error(`After 10 try must remove `, removedAction);
      this._actionRunning = false;
    }
  }

  protected async searchForAction() {
    if (this._game && this._game.isSelectedForBot) {
      console.log(`--- check for new actions on game ${this._game.name} ---`);
      const gameActions = await this._game.autoActions();
      if (gameActions) {
        console.log(`found ${gameActions.length} actions on game ${this._game.name} ---`);
        this._actions = this._actions.concat(gameActions);
      }
    }
  }

  protected async isCPUAvailable() {
    const pctCPU = UserStore.getInstance().getCPUUsagePercent();
    if (pctCPU && pctCPU > this._cpuLimit) {
      console.warn(`No CPU ailable ${pctCPU} skip this loop for game ${this._game.name}`);
      runInAction(async ()=> {
        this._lastUpdate = new Date();
      })        
      return false;
    }
    return true;

  }

  protected async playNextAction() {
      // si on trouves des actions le bot va lancer une action à chaque fois 
      console.log(`must play next action ${this._actions.length}`);
      const action = this._actions.shift();
      if (action) {
        console.log(action);
        try {
          this._actionRunning = true;
          this._retryCount = 0;

          this._lastActionSend = action;
          await this._game.runTransaction(action);

          await this._game.finalizeAction(action);

          this._lastActionSend = null;
          this._actionRunning = false;
        }
        catch (e) {
          
          if (e instanceof RpcError) {
            console.error(`${this.game.name} get error = ${e.message}`);

            const correctAction = this.game.handleRpcError(action, e as RpcError);
            if (correctAction) {
              // Unknown action mine in contract stakeanimal1 
              // Error: billed CPU time (379 us) is greater than the maximum billable CPU time for the transaction (0 us) 
                console.warn(`will play the action after error ${correctAction.name}`)
                this._actions.unshift(correctAction);
            }
            else {
              console.warn(`${this.game.name} drop action ${action.name}`);
            }  
            this._lastActionSend = null;
            this._actionRunning = false;  
          }
          else {
            console.error(e);
            console.warn(`will re-play the action ${action.name}`);
            this._actions.unshift(action);
            this._lastActionSend = null;
            this._actionRunning = false;  
          }
        }
        await this._game.updateAssets();
      }
    
  }

  protected async mainLoop() {
    console.log(`runing ${this._game.name} bot`);

    if (this._actionRunning) {
      this.updateActionRetry();
    }    

    // pb du post signing qui plante mais que l'on peut pas catch
    if (this._actionRunning && this._actions.length === 0) {
      console.warn('something where wrong');
      if (this._lastActionSend) {
        this._actions.push(this._lastActionSend);
      }
    }

    if (this._actions.length === 0) {
      await this.searchForAction();
    }  

    // si on a des actions précédentes ou on a trouvé de nouvelles actions 
    if (this._actions.length !== 0) {
      // check if CPU is available 
      const cpu = await this.isCPUAvailable();
      if (!cpu) {
        return;
      }
      await this.playNextAction();
    }

    runInAction(async ()=> {
      this._lastUpdate = new Date();
    })
  }

  public stopWatch() {
    if (this._mainLoopId) {
      console.log(`stop ${this._game.name} bot`);
      clearInterval(this._mainLoopId);
      runInAction(async ()=> {
        this._isReady = false;
        this._isRunning = true;
      })
  
    }
  }

  public get lastUpdate(): Date {
    return this._lastUpdate;
  }
 
}