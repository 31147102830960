import axios, { AxiosInstance } from "axios";

export interface AtomicParam {
    page?: number,
    limit?: number,
    collection_name?: string,
    schema_name?: string,
    order?: string,
    sort?: string,    
}

export interface AtomicAssetParam extends AtomicParam {
  owner?: string,
  ids?: string,
}

export interface AtomicCollection {
  allow_notify?:boolean,
  author: string,
  authorized_accounts: string[],
  collection_name: string,
  created_at_block: number,
  created_at_time: number,
  img: string,
  market_fee: number,
  name: string,
  notify_accounts: string[]
}

export interface AtomicSchemaFormat {
  name: string,
  type: string
}

export interface AtomicSchema {
  schema_name: string,
  format: AtomicSchemaFormat[]
}

export interface AtomicTemplate {
  template_id: string,
  data?: any,
  immutable_data?: any,
  mutable_data?: any,
  issued_supply: number,
  max_supply: number,
}

export interface AtomicAsset {
  asset_id: string,
  contract: string,
  name: string,
  owner: string,
  data?: any,
  immutable_data?: any,
  mutable_data?: any,
  is_burnable: boolean,
  is_transferable: boolean,
  schema?: AtomicSchema,
  collection?:AtomicCollection,
  template?:AtomicTemplate
}


// https://wax.api.atomicassets.io/atomicassets/v1/assets?page=1&limit=1000&collection_name=centurytrain&schema_name=station
// template
// https://wax.api.atomicassets.io/atomicassets/v1/templates?page=1&limit=1000&collection_name=officelandio&schema_name=staffs
// docs 
// https://test.wax.api.atomicassets.io/docs/

export default class AtomicAssetClient {

  protected _client:AxiosInstance; 

  constructor() {
    this._client = axios.create({
      baseURL: 'https://wax.api.atomicassets.io',
      timeout: 10000,
    });
  }



  public async loadAssetsByCollection(collection:string) {
    
  }

  public async loadTemplates(params:AtomicParam):Promise<AtomicTemplate[]> {
    let result: AtomicTemplate[] = [];
    try {
        const assets = await this._client.get('/atomicassets/v1/templates', {
          params,
        })  
        result = assets['data']['data'];
      }
      catch (e) {
        console.error(e);
      }
  
    return result;
  }

  public async loadAssets(params:AtomicAssetParam): Promise<AtomicAsset[]> {

    let result: AtomicAsset[] = [];

    try {
      const assets = await this._client.get('/atomicassets/v1/assets', {
        params,
      })  
      result = assets['data']['data']
    }
    catch (e) {
      console.error(e);
    }

    return result;
    
  }


}