import { observer } from "mobx-react-lite";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import GameContract from "../../model/contract/GameContract";
import UserStore from "../../model/UserStore";

interface GameCardProps {
  game: GameContract;
}


const GameCard = ({game}:GameCardProps): React.ReactElement => {
  const [checked, setChecked] = React.useState(game.isSelectedForBot);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleConfig = () => {
    navigate(`/config/${game.name}`);
  }

  const handleChangeStatus = () => {
    const next = !checked;
    UserStore.getInstance().switchSelected(game, next);
    setChecked(next)
  }

  return (
    <div className="game-card">
      <h2>{ t([`games.${game.name}.title`]) }</h2>  

      <label className="switch">
      <input type="checkbox" defaultChecked={checked} onChange={ handleChangeStatus }></input>
        <span className="slider round"></span>
      </label>
      <button className="btn-config" onClick={handleConfig}><i className="fas fa-cog"></i></button>
    </div>
  )
};


const Home = observer((): React.ReactElement => { 
    const userStore = UserStore.getInstance();
    
    
    const games = Array.from(userStore.botStore.bots.values()).map((bot) => {
      return (
        <GameCard key={bot.game.name} game={bot.game}></GameCard>
      )
    })

    return <div className="container game-selection content">
        <h2>Select games</h2>
        { games }
    </div>

});

export default Home;
