import EOSClient, { prepareTableRequest } from "../RPCClient";
import Asset from "./Asset";

/**
 * https://wax.bloks.io/account/atomicassets
 */

export interface AtomicSearchParam {
  collection_name?: string
  next_key?: string,
}

export default class AtomicAssetContract {

  private _assets: Asset[] = [];
  private _client: EOSClient;

  constructor(client: EOSClient) {
    this._client = client;
  }

  public async loadAssets(params?: AtomicSearchParam): Promise<Asset[]> {
    this._assets = [];

    const finalParam: AtomicSearchParam = {
      next_key: params?.next_key ? params.next_key : '',
      collection_name: params?.collection_name 
    }

    await this.walkTAssets(finalParam);

    return this._assets;
  }

  // TODO : not working
  // use Atomicclient instead 
  // page: 1
  // limit: 500
  // owner: qwnho.wam
  // collection_name: nftpandawaxp
  // schema_name: food

  public async loadAssetsByCollection(collectionName:string) {
    const client = this._client;
    try {
      const request = prepareTableRequest({
        code: 'atomicassets',
        scope: client.userAccount,
        table: 'assets',
        lower_bound: collectionName,
        index_position: 2,
        key_type: 'name'
      });  

      console.log(request);
      const results = await client.rpc.get_table_rows(request);
      console.log(results);
    }
    catch (e) {
      console.error(e);
    }
  }

  protected async walkTAssets(params: AtomicSearchParam) {
    const client = this._client;
    try {
      const request = prepareTableRequest({
        code: 'atomicassets',
        scope: client.userAccount,
        table: 'assets',
      });

      if (params.collection_name !== undefined) {
        request.key_type = 'name';
        request.index_position = 2;
        request.lower_bound = params.collection_name;
      }
       
      const results = await client.rpc.get_table_rows({
        json: true,
        code: 'atomicassets',
        scope: client.userAccount,
        table: 'assets',
        lower_bound: params?.next_key === '' ? '' : params?.next_key,
        limit: 100,
        reverse: false,
        show_payer: false
      });
      // console.log(results);
      const rows = results['rows'];
      rows.forEach((row) => {
        //console.log(row);
        const asset = new Asset(row['asset_id']);
        asset.colectionName = row['collection_name'];
        asset.schemaName = row['schema_name'];
        asset.templateId = row['template_id'];
        asset.owner = client.userAccount;
        this._assets.push(asset);
      });
      if (results['next_key'] !== '') {
        // console.log('must continue to walk');
        params.next_key = results['next_key']
        await this.walkTAssets(params);
      }
    }
    catch (e) {
      console.error(e);
    }
  }


}
