import React from "react";
import { GameProps } from "../../../views/components/GameView";
import NftPandaContract from "../model/NftPandaContract";
import TimeLeftView from "../../../views/components/TimeLeftView";

const PandaTableView: React.FunctionComponent<GameProps>  = props => {
  
  const contract = props.game as NftPandaContract;

  const slots = Array.from(contract.slots.values()).map((slot) => {
    const timeLeft = contract.getTimeleft(slot);
    const nextRank = contract.getNextStep(slot.lvl);
    const weapon = contract.getWeapon(slot.asset_id);
    const jewerly = contract.getJewerly(slot.asset_id);
    const name = slot.name_pa.split('-')[1];
    return (
          <tr key={slot.asset_id}>
            <td className="td-name">{ name }</td>
            <td className="td-lvl">{ slot.lvl }</td>
            <td className="td-energy">{ slot.energy/100 }</td>
            <td className="td-weapon">{ weapon ? <div>{ weapon.energy / 100 } %</div> : <></> }</td>
            <td className="td-jewerly">{ jewerly ? <div>{ jewerly.energy / 100 } %</div> : <></> }</td>
            <td className="td-steps">{ slot.steps + '/' + nextRank.level }</td>
            <td className="td-timeleft"><TimeLeftView timeleft={timeLeft}></TimeLeftView></td>
          </tr>
    )
  });

  return (
    <div className="panda-slot table-container">
      <table className="styled-table">
        <thead>
          <tr>
            <td className="td-name">Name</td>
            <td className="td-lvl">Lvl</td>
            <td className="td-energy">Energy</td>
            <td className="td-weapon">Weapon</td>
            <td className="td-jewerly">Jewerly</td>
            <td className="td-steps">Steps</td>
            <td className="td-timeleft">TimeLeft</td>
          </tr>
        </thead>
        <tbody>
          { slots }
        </tbody>
      </table>
    </div>

  );
}

const NftPandaBotView: React.FunctionComponent<GameProps> = props => {

  const contract = props.game as NftPandaContract;
    /*
  const handleBuyFood = async () => {
    const action = contract.createBuyFoodAction('common', PRICE_FOOD_COMMON, 5);
    await action.game.runTransaction(action);
  }
*/
  return (
    <div> 
      <div className="game-info">
        <p>Foods: <strong>{ contract.getNumFoods() }</strong></p>
      </div>     
      <PandaTableView game={props.game}></PandaTableView>
    </div>
  )
}

// <button className="btn" onClick={handleBuyFood}>Buy Food</button>

export default NftPandaBotView;