import React from "react";
import { observer } from "mobx-react-lite";
import UserStore from "../../model/UserStore";
import { Link } from "react-router-dom";

const Header = observer((): React.ReactElement => { 
    const userStore = UserStore.getInstance();
    return <header className="header">
            { userStore.isLogged() 
                    ? <div className="container">
                        <div className="account">
                          <label>Account</label>
                          <Link to="/" className="user">{userStore.client.userAccount}</Link>
                          
                        </div>
                        <span className="metrics">
                            <span className="selected">WAX: </span>{ userStore.balance ? userStore.balance!.value.toFixed(0):'0' } 
                            <span className="selected">CPU: </span>{ userStore.getResouceUsagePercent('cpu', true) }% 
                             <span className="selected">NET: </span>{ userStore.getResouceUsagePercent('net', true) }%
                        </span>
                      </div> 
                    : ''}
      </header>
});

export default Header;
