import React from "react";
import { GameProps } from "../../../views/components/GameView";
import OfficeLandContract from "../model/OfficeLandContract";
import TimeLeftView from "../../../views/components/TimeLeftView";

//   <div key={assign.asset_id}>{asset.name} {asset.asset_id} task:{assign.task_id} </div>

const StaffView: React.FunctionComponent<GameProps> = props => {
  const contract = props.game as OfficeLandContract;
  const staff = contract.tasksAssign.map((assign)=> {
    const template = contract.getTemplateFromAsset(assign.asset_id)!;
    const name = template ? template.immutable_data.name:'error';
    const rarity = template ? template.immutable_data.rarity:'error';
    // const stat = contract.assetStats.get(assign.asset_id);
    // console.log(contract.assetStats, assign.asset_id, contract.assetStats.get(assign.asset_id), stat);
    return (
      <tr key={assign.asset_id}>
        <td className="td-name">{ name } { contract.isWaitingForUpdate(assign.asset_id) ? '*':''}</td>
        <td className="td-rarity">{ rarity }</td>
        <td className="td-stats">{ contract.getDecreaseRate(assign.asset_id).toFixed(2) }%</td>
        <td className="td-task">{assign.task_id}</td>
        <td className="td-timeleft"><TimeLeftView timeleft={contract.getTimeleft(assign)}></TimeLeftView></td>
      </tr>
    )
  })  
  return (
    <div className="staff table-container">      
      <table className="styled-table">
        <thead>
          <tr>
            <td className="td-name">Name</td>
            <td className="td-rarity">Type</td>
            <td className="td-stats">Stats</td>
            <td className="td-task">Task</td>
            <td className="td-timeleft">TimeLeft</td>
          </tr>
        </thead>
        <tbody>
          { staff }
        </tbody>
      </table>
    </div>
  )
}

const OLBotView: React.FunctionComponent<GameProps> = props => {
  const contract = props.game as OfficeLandContract;
  return (
    <div>
      <div className="game-info">
        <p>Instant Rewards: <strong>{contract.instantRewards.toFixed(2)}</strong> </p>
        <p>Rewards: <strong>{contract.rewards.toFixed(2)}</strong></p>
      </div>
      <div className="game-info">
        <p>Task to claim <strong>{contract.getTaskToClaim().length}</strong></p>
      </div>
      <StaffView game={props.game}></StaffView>
    </div>
  )
}

export default OLBotView;