import { AnnotationsMap, makeObservable, observable, runInAction } from "mobx";

export default class Settings {

  protected _botLoopInSeconds:number;

  constructor() {
    
    this._botLoopInSeconds = 15;

    makeObservable(this, {
      _botLoopInSeconds: observable
    } as AnnotationsMap<this, string>);

  }

  public get botLoopInSeconds() {
    return this._botLoopInSeconds;
  }

  public set botLoopInSeconds(seconds:number) {
    runInAction(() => {
      this._botLoopInSeconds = seconds;
    })
  }

}