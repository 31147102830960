import { AtomicAsset } from "../../../model/atomic/AtomicAssetClient";
import GameContract, { TransactionAction } from "../../../model/contract/GameContract";
import EOSClient, { prepareTableRequest } from "../../../model/RPCClient";


/**
 * https://wax.bloks.io/account/critterclaim
 * 
 * 	critterclaim - claimstake
 * 
 * asset_ids:
    1099593328127
    best_building_id: 1099593328047
    user: qwnho.wam
*/

interface Claim {
 	asset_id: string,
  last_claim: number,
  music?:MusicAsset,
}

interface MusicAsset extends AtomicAsset {
  data: {
    description: string,
    name: string, 
    artist: string,
    rarity: string,
    rarity_number: string,
    record_number: string,
  }
}

interface BuildAsset extends AtomicAsset {
  data: {
    description: string,
    name: string, 
    rarity: string,
    rarity_number: string,
    number: string,
  }
}

interface StickerAsset extends AtomicAsset {

  data: {
    description: string,
    name: string, 
    rarity: string,
    rarity_number: string,
    sticker_number: string,
  }
}

export default class CrittercraftContract extends GameContract {

  static NAME = 'critterclaim';
  static CONTRACT_NAME = 'critterclaim';  
  static COLLECTION_NAME = 'crittercraft' 
  static TOKEN_CONTRACT = 'crittertoken'

  private _musics:Map<string, MusicAsset>;
  private _builds:Map<string, BuildAsset>;
  private _stickers:Map<string, StickerAsset>;
  private _claims:Claim[] = [];

  private _musicCoolDown:number = 12 * 3600;

  constructor(client:EOSClient) {
    
    super(client);

    this._name = CrittercraftContract.NAME;
    this._contractName = CrittercraftContract.CONTRACT_NAME;
    this._tokenContract = CrittercraftContract.TOKEN_CONTRACT;
    this._collectionName = CrittercraftContract.COLLECTION_NAME;
    this._defaultSymbol = 'MOTIV';

    this._musics = new Map();
    this._builds = new Map();
    this._stickers = new Map();
    this._claims = [];

  }

  public loadAssets = async () => {

    await this.loadAtomicAssets();

    await this.loadClaims();

    await super.loadAssets();

    return this;
  }

  public updateAssets = async() => {

    await this.loadAtomicAssets();

    await this.loadClaims();
    
    await super.updateAssets();
    
    return this;
  }  

  public getTimeleft(item:Claim): number {
    const t = item.last_claim - (new Date().getTime() / 1000) + this._musicCoolDown;
    return Math.round(t);
  }

  /*
   * 	critterclaim - claimstake
 * 
 * asset_ids:
    1099593328127
    best_building_id: 1099593328047
    user: qwnho.wam
    */

  protected getBestBuilding() {
    return Array.from(this._builds.values())[0];
  }


  public async autoActions(): Promise<TransactionAction[]> {

    await this.updateAssets();
    
    const actions: TransactionAction[] = [];

    this._claims.forEach((claim) => {
      const tl = this.getTimeleft(claim);
      if (tl < 0) {
        actions.push(
          this.createGameActions(
            CrittercraftContract.NAME, 
            this._client.userAccount, 
            'claimstake', 
            {
              asset_ids: [claim.asset_id],
              best_building_id: this.getBestBuilding().asset_id,
              user: this._client.userAccount
            }
          )
        );          
      }
    });

    return actions;
  }

  public get claims() {
    return this._claims;
  }

  protected async loadAtomicAssets() {

    const assets = await super.loadAtomicAssets();

    this._builds = new Map();
    this._musics = new Map();
    this._stickers = new Map();

    assets.forEach((asset)=>{
      const schemaName = asset.schema?.schema_name;
      switch(schemaName) {
        case 'music':
          this._musics.set(asset.asset_id, asset as MusicAsset);
          break;
        case 'buildings':
          this._builds.set(asset.asset_id, asset as BuildAsset);
          break;
        case 'stickers':
          this._stickers.set(asset.asset_id, asset as StickerAsset);
          break;              
      }
    })

    return assets;
  }

  protected async loadClaims() {
    
    this._claims = [];

    for (let music of Array.from(this._musics.values())) {
      const request = prepareTableRequest({
        code: CrittercraftContract.CONTRACT_NAME,
        scope: CrittercraftContract.CONTRACT_NAME,
        table: 'claims',
        lower_bound: music.asset_id,
        upper_bound: music.asset_id,
      })
      try {
        const result = await this.requestTable(request);
        const rows = result!['rows'];
        const claim = rows[0] as Claim;
        claim.music = music;
        this._claims.push(claim);
      }
      catch (e) {
        console.log(e);
      }
    }
  }

}



