import AtomicAssetClient, { AtomicAsset } from "../../../model/atomic/AtomicAssetClient";
import GameContract, { TokenBalance, TransactionAction } from "../../../model/contract/GameContract";
import EOSClient, { prepareTableRequest } from "../../../model/RPCClient";
import { RpcError} from 'eosjs';

export interface ToolConf {
    template_name: string,
    schema_name: string,
    type: string,
    rarity: string,
    level: number,
    template_id: string,
    energy_consumed: number,
    durability_consumed: number,
    mints: string[],
    rewards: string,
    charged_time: number
};

export interface AssetWithAvailabilty {
    asset_id: string,
    next_availability: number
}

export interface Tool extends AssetWithAvailabilty {
    owner: string,
    type: string,
    template_id: number,
    durability: number,
    current_durability: number,
    config?: ToolConf
};

export interface Member extends AssetWithAvailabilty {
    owner: string,
    type: string,
    template_id: number,
    unstaking_time: number,
    config?: MemberConfig
};

export interface MemberConfig {
    name: string,
    template_id: string,
    type: string,
    img: string,
    badge_img: string,
}

export interface FWGameAccount {
    account: string,
    balances?: TokenBalance[],
    energy: number,
}

export interface GameConfig {
    init_energy: number,
    init_max_energy: number,
    reward_noise_min: number,
    reward_noise_max: number,
    min_fee: number,
    max_fee: number,
    last_fee_updated: number,
    fee: number
}


export interface BuildingConf {
    template_id: number,
    name: string,
    img: string,
    charge_time: number,
    required_claims: number,
    energy_consumed: number,
}

export interface Building extends AssetWithAvailabilty {
    is_ready: number,
    name: string,
    owner: string,
    slots_used: number,
    template_id: number,
    times_claimed: number,
    config?: BuildingConf,
}

export interface CropConf {
    template_id: number,
    name: string,
    img: string,
    charge_time: number,
    required_claims: number,
    energy_consumed: number,
    reward_card: number,
    required_building: number,
    miss_claim_limit: number,
}

export interface Crop extends AssetWithAvailabilty {
    owner: string,
    name: string,
    building_id: number,
    template_id: number,
    times_claimed: number,
    last_claimed: number,
    conf?: CropConf,
}

export interface AnimalConf {
    breeding_partner: number,
    charge_time: number,
    consumed_card: number,
    consumed_quantity: number,
    daily_claim_limit: number,
    energy_consumed: number,
    evolved_card: number,
    gender: number,
    name: string,
    required_building: number,
    required_claims: number,
    reward_card: number,
    template_id: number,
}

export interface Animal extends AssetWithAvailabilty {
    building_id: string,
    day_claims_at: number[],
    gender: number,
    last_claimed: number,
    name: string,
    owner: string,
    partner_id: number,
    template_id: number,
    times_claimed: number,
    conf?: AnimalConf,
}

/**
 * https://wax.bloks.io/account/farmersworld
 * 
 * must get config to show fee
 * 
 * 
 * 
 * 
 * 
> breedings
code: "farmersworld"
index_position: 2
json: true
key_type: "i64"
limit: "100"
lower_bound: "qwnho.wam"
reverse: false
scope: "farmersworld"
show_payer: false
table: "breedings"
upper_bound: "qwnho.wam"

> crops
code: "farmersworld"
index_position: 2
json: true
key_type: "i64"
limit: "100"
lower_bound: "qwnho.wam"
reverse: false
scope: "farmersworld"
show_payer: false
table: "crops"
upper_bound: "qwnho.wam"

> animals
code: "farmersworld"
index_position: 2
json: true
key_type: "i64"
limit: "100"
lower_bound: "qwnho.wam"
reverse: false
scope: "farmersworld"
show_payer: false
table: "animals"
upper_bound: "qwnho.wam"

> buildings
code: "farmersworld"
index_position: 2
json: true
key_type: "i64"
limit: "100"
lower_bound: "qwnho.wam"
reverse: false
scope: "farmersworld"
show_payer: false
table: "buildings"
upper_bound: "qwnho.wam"
>> 


> tassets
code: "farmersworld"
index_position: 2
json: true
key_type: "i64"
limit: 100
lower_bound: "qwnho.wam"
reverse: false
scope: "farmersworld"
show_payer: false
table: "tassets"
upper_bound: "qwnho.wam"

chicken eat bareley 
https://wax.bloks.io/transaction/244d02f99da30f97b7a6a223001ee93bede728f2ff9069e1a7bba41031cb2c55

atomicassets
  transfer
    asset_ids: 
      1099615245326
    from: qwnho.wam
    memo: feed_animal:1099612386417
    to: farmersworld



    
 */

export default class FarmersWorldContract extends GameContract {

    static NAME = 'farmersworld';
    static CONTRACT_NAME = 'farmersworld';
    static COLLECTION_NAME = 'farmersworld';
    static TOKEN_CONTRACT = 'farmerstoken'

    private _toolsConf: Map<string, ToolConf>;
    private _tools: Tool[];
    private _account: FWGameAccount;

    private _mbsConfig: Map<string, MemberConfig>;

    private _building: Building[];
    private _buildingConf: Map<number, BuildingConf>;

    private _crops: Crop[];
    private _cropsConfig: Map<number, CropConf>;

    private _animals: Animal[];
    private _animalsConfig: Map<number, AnimalConf>;

    private _mbs: Member[];
    private _claimCoins: boolean = true;
    private _claimMember:boolean = false;

    private _foods: AtomicAsset[];

    private _gameConfig: GameConfig = {
        init_energy: 0,
        init_max_energy: 0,
        reward_noise_min: 0,
        reward_noise_max: 0,
        min_fee: 0,
        max_fee: 0,
        last_fee_updated: 0,
        fee: 0
    };

    constructor(client: EOSClient) {
        super(client);
        // console.log('[FarmersWorldContract] constructor');

        this._name = FarmersWorldContract.NAME;
        this._contractName = FarmersWorldContract.NAME;
        this._collectionName = FarmersWorldContract.COLLECTION_NAME;
        this._tokenContract = FarmersWorldContract.TOKEN_CONTRACT;

        this._foods = [];

        this._toolsConf = new Map();
        this._tools = [];

        this._mbsConfig = new Map<string, MemberConfig>();
        this._mbs = [];
        this._claimCoins = true;

        this._building = [];
        this._buildingConf = new Map();

        this._crops = [];
        this._cropsConfig = new Map();

        this._animals = [];
        this._animalsConfig = new Map();

        this._account = {
            account: '',
            energy: 0
        }

    }

    public static getToolTimeLeft(tool: Tool) {
        const t = tool.next_availability - Date.now() / 1000;
        return Math.round(t);
    }

    public get claimMember() {
        return this._claimMember;
    }

    public loadAssets = async () => {

        await this.loadFoods();

        //
        this._toolsConf = await this.loadConfTools();
        //
        this._tools = await this.loadTools();
        //
        this._mbsConfig = await this.loadMbsConfig();
        this._mbs = await this.loadMbs();
        // 
        await this.loadBuildingConf();
        await this.loadBuilding();
        //
        await this.loadCropConfig();
        await this.loadCrops();
        // 
        await this.loadAnimalConfig();
        await this.loadAnimals();
        // 
        await this.loadGameConfig();

        await this.loadGameAccount();

        await super.loadAssets();
        //
        return this;
    }

    public updateAssets = async () => {
        
        await this.loadFoods();

        this._tools = await this.loadTools();
        this._mbs = await this.loadMbs();
        //
        await this.loadBuilding();
        //
        await this.loadCrops();
        // 
        await this.loadAnimals();
        //
        await this.loadGameConfig();
        await this.loadGameAccount();
        //
        await super.updateAssets();
        //
        return this;
    }


    public loadGameConfig = async () => {
        const client = this._client;
        try {
            const results = await client.rpc.get_table_rows({
                json: true,
                code: FarmersWorldContract.NAME,
                scope: FarmersWorldContract.NAME,
                table: 'config',
                limit: 1,
                reverse: false,
                show_payer: false
            });
            this._gameConfig = results['rows'][0];
        }
        catch (e) {
            console.error(e);
        }
    }

    public loadGameAccount = async () => {
        const client = this._client;
        try {
            const results = await client.rpc.get_table_rows({
                json: true,
                code: FarmersWorldContract.NAME,
                scope: FarmersWorldContract.NAME,
                table: 'accounts',
                lower_bound: client.userAccount,
                upper_bound: client.userAccount,
                limit: 1,
                reverse: false,
                show_payer: false
            });
            const row = results['rows'][0];
            this._account = {
                account: row['account'],
                energy: row['energy'],
                balances: []
            }

            row['balances'].forEach((element: any) => {
                const a = element.split(' ');
                // console.warn(a, element);
                const tokenB = {
                    symbol: a[1],
                    value: parseFloat(a[0])
                };
                this._account.balances?.push(tokenB);
                this._tokens.set(tokenB.symbol, tokenB)
            });

            // results['rows'][0];
        }
        catch (e) {
            console.error(e);
        }
    }

    public get foods():AtomicAsset[] {
        return this._foods;
    }

    public get barleys():AtomicAsset[] {
        const barleys: AtomicAsset[] = [];
        this._foods.forEach((food)=> {
            if (food.name === 'Barley') {
                barleys.push(food);
            }
        })
        return barleys;
    }

    /******************************************
     * ANIMALS
    ******************************************/
     protected loadFoods = async () => {
        const client = new AtomicAssetClient();
        try {
            const result = await client.loadAssets({
                collection_name: this._collectionName,
                schema_name: 'foods',
                owner: this._client.userAccount
            })
            if (result) {
                this._foods = result
            }
        }
        catch (e) {
            console.error(e);
        }
    }
    
    protected getFoodByName(name:string):AtomicAsset|null {
        for (let asset of this._foods) {
            if (asset.name === name) return asset;
        }
        return null;
    }

    public get animals() {
        return this._animals;
    }

    public async loadAnimalConfig() {
        const request = prepareTableRequest({
            code: FarmersWorldContract.NAME,
            scope: FarmersWorldContract.NAME,
            table: 'anmconf',
        })
        const results = await this.requestTable(request);
        if (results) {
            const rows = results['rows'];
            rows.forEach((row) => {
                this._animalsConfig.set(row['template_id'], row);
            })
        }
    }

    public async loadAnimals() {
        const request = prepareTableRequest({
            code: this._contractName,
            scope: this._contractName,
            table: "animals",
            index_position: 2,
            key_type: "i64",
            lower_bound: this._client.userAccount,
            upper_bound: this._client.userAccount,
        })
        const results = await this.requestTable(request);
        // console.log(results);
        if (results) {
            this._animals = results['rows'];
            this._animals.forEach((a) => {
                a.conf = this._animalsConfig.get(a.template_id);
            })
        }
    }

    /******************************************
     * PLANTS
    ******************************************/
    public get crops() {
        return this._crops;
    }

    public async loadCropConfig() {
        const request = prepareTableRequest({
            code: FarmersWorldContract.NAME,
            scope: FarmersWorldContract.NAME,
            table: 'cropconf',
        })
        const results = await this.requestTable(request);
        if (results) {
            const rows = results['rows'];
            rows.forEach((row) => {
                this._cropsConfig.set(row['template_id'], row);
            })
        }
    }

    public async loadCrops() {
        const request = prepareTableRequest({
            code: this._contractName,
            scope: this._contractName,
            table: "crops",
            index_position: 2,
            key_type: "i64",
            lower_bound: this._client.userAccount,
            upper_bound: this._client.userAccount,
        })
        const results = await this.requestTable(request);
        // console.log(results);
        if (results) {
            this._crops = results['rows'];
            this._crops.forEach((c) => {
                c.conf = this._cropsConfig.get(c.template_id);
            })
        }
    }


    /******************************************
     * BUILDINGS
    ******************************************/

    public get building() {
        return this._building;
    }

    public async loadBuilding() {
        const request = prepareTableRequest({
            code: this._contractName,
            scope: this._contractName,
            table: "buildings",
            index_position: 2,
            key_type: "i64",
            lower_bound: this._client.userAccount,
            upper_bound: this._client.userAccount,
        })
        const results = await this.requestTable(request);
        if (results) {
            this._building = results['rows'];
            this._building.forEach((b) => {
                b.config = this._buildingConf.get(b.template_id);
            })
        }
    }

    protected async loadBuildingConf() {
        const request = prepareTableRequest({
            code: FarmersWorldContract.NAME,
            scope: 'farmersworld',
            table: 'bldconf',
        })

        const results = await this.requestTable(request);
        if (results) {
            const rows = results['rows'];
            rows.forEach((row) => {
                this._buildingConf.set(row['template_id'], row);
            })
        }
    }

    /******************************************
     * MEMBERSHIPS
    ******************************************/
    public loadMbsConfig = async (): Promise<Map<string, MemberConfig>> => {
        const configurations: Map<string, MemberConfig> = new Map();
        const client = this._client;
        try {
            const results = await client.rpc.get_table_rows({
                json: true,
                code: FarmersWorldContract.NAME,
                scope: 'farmersworld',
                table: 'mbsconf',
                limit: 100,
                reverse: false,
                show_payer: false
            });
            const rows = results['rows'];
            rows.forEach((row) => {
                configurations.set(row['template_id'], row);
            });
        }
        catch (e) {
            console.error(e);
        }

        return configurations;
    }

    public loadMbs = async (): Promise<Member[]> => {
        const members: Member[] = [];
        const client = this._client;
        try {
            const results = await client.rpc.get_table_rows({
                json: true,
                code: FarmersWorldContract.NAME,
                scope: FarmersWorldContract.NAME,
                table: 'mbs',
                index_position: 2,
                key_type: 'name',
                lower_bound: client.userAccount,
                limit: 50,
                reverse: false,
                show_payer: false
            });
            const rows = results['rows'];
            rows.forEach((row) => {
                // console.log(row);
                const owner: string = row['owner'];
                if (owner === client.userAccount) {
                    const item: Member = row;
                    members.push(item);
                    if (this._mbsConfig.has(row['template_id'])) {
                        item.config = this._mbsConfig.get(row['template_id']);
                    }
                }
            });
        }
        catch (e) {
            console.error(e);
        }
        return members;
    }

    public get mbs(): Member[] {
        return this._mbs;
    }

    /******************************************
     * TOOLS
    ******************************************/
    public loadTools = async (): Promise<Tool[]> => {
        const tools: Tool[] = [];
        const client = this._client;
        try {
            const results = await client.rpc.get_table_rows({
                json: true,
                code: FarmersWorldContract.NAME,
                scope: FarmersWorldContract.NAME,
                table: 'tools',
                index_position: 2,
                key_type: 'name',
                lower_bound: client.userAccount,
                limit: 50,
                reverse: false,
                show_payer: false
            });
            const rows = results['rows'];
            rows.forEach((row) => {
                // console.log(row);
                const owner: string = row['owner'];
                if (owner === client.userAccount) {
                    const t: Tool = row;
                    tools.push(t);
                    if (this._toolsConf.has(row['template_id'])) {
                        t.config = this._toolsConf.get(row['template_id']);
                    }
                }
            });
        }
        catch (e) {
            console.error(e);
        }
        return tools;
    }

    public loadConfTools = async (): Promise<Map<string, ToolConf>> => {
        const configurations: Map<string, ToolConf> = new Map<string, ToolConf>();
        const client = this._client;
        try {
            const results = await client.rpc.get_table_rows({
                json: true,
                code: FarmersWorldContract.NAME,
                scope: 'farmersworld',
                table: 'toolconfs',
                limit: 100,
                reverse: false,
                show_payer: false
            });
            const rows = results['rows'];
            rows.forEach((row) => {
                configurations.set(row['template_id'], row);
            });
        }
        catch (e) {
            console.error(e);
        }
        return configurations;
    }

    public get tools(): Tool[] {
        return this._tools;
    }

    /******************************************
     * 
    ******************************************/

    public getTimeLeft(asset: AssetWithAvailabilty) {
        const t = asset.next_availability - Date.now() / 1000;
        return Math.round(t);
    }

    public get account(): FWGameAccount {
        return this._account;
    }

    public get gameConfig() {
        return this._gameConfig;
    }

    /******************************************
     * bot actions 
    ******************************************/
    public getTimeForNextAnimalClaim(animal:Animal) {
        const firstClaim = animal.day_claims_at[0];
        const now = new Date().getTime() / 1000;
        const dt = now - firstClaim;
        return Math.floor(24 * 60 * 60 - dt);
    }

    public async autoActions(): Promise<TransactionAction[]> {

        const client = this._client;

        await this.updateAssets();
        // return [];

        // check all toos
        const actions: TransactionAction[] = [];

        // check if energy is available 
        // https://wax.bloks.io/transaction/6edc0d8464b623de20ca1ce56f79e06531435eafac42b558ade8627545574eb1
        if (this._account.energy <= 300) {
            console.warn('must recharge enery');
            const max = 900;
            const fwf = this._tokens.get('FOOD');
            if (fwf) {
                const diff = max - this._account.energy;
                const cost = diff / 5;
                const payed = Math.min(cost, Math.floor(fwf?.value!));
                console.log(`diff ${diff} fwf:${fwf?.value} cost=${cost} payed=${payed}`);    
                const action = this.createGameActions(
                    this._contractName, 
                    this._client.userAccount,
                    'recover', {
                        energy_recovered: payed * 5,
                        owner: this._client.userAccount
                    }
                )
                actions.push(action);
            }
        }
        if (actions.length !== 0) return actions;

        // checks buildings 
        this._building.forEach((building) => {
            if (building.is_ready === 0) {
                const timeLeft = this.getTimeLeft(building);
                // check enery 
                if (timeLeft < 0 && building.config?.energy_consumed! < this.account.energy) {
                    actions.push(this.createGameActions(this._contractName,
                        this._client.userAccount,
                        'bldclaim',
                        {
                            asset_id: building.asset_id,
                            owner: this._client.userAccount,
                        }
                    ));
                }
            }
        })
        if (actions.length !== 0) return actions;

        // check crops
        this._crops.forEach((crop) => {

            const timeLeft = this.getTimeLeft(crop);

            if (timeLeft < 0 && (crop.times_claimed <= crop.conf?.required_claims!)) {
                console.warn('must crops !!!');
                actions.push(this.createGameActions(this._contractName,
                    this._client.userAccount,
                    'cropclaim',
                    {
                        crop_id: crop.asset_id,
                        owner: this._client.userAccount,
                    }
                ));
            }

        });
        if (actions.length !== 0) return actions;


        // check animals
        this._animals.forEach((animal) => {
            
            // console.log(animal);

            const timeLeft = this.getTimeLeft(animal);
            const isMaxClaim = animal.times_claimed >= animal.conf?.required_claims!;

            let isMaxClaimForThisDay = false;
            const numClaimedDay = animal.day_claims_at.length;
            let isEgg = animal.name === 'Chicken Egg';            
            if (!isMaxClaim && numClaimedDay === animal.conf?.daily_claim_limit!) {
                const firstClaim = animal.day_claims_at[0];
                const now = new Date().getTime() / 1000;
                const dt = now - firstClaim;
                isMaxClaimForThisDay = dt < 24 * 60 * 60;
            }
            // console.log(animal);
            // console.log(`timeLeft = ${timeLeft} isMaxClaim=${isMaxClaim} numClaimedDay=${numClaimedDay} isMaxClaimForThisDay=${isMaxClaimForThisDay}`);
            if (timeLeft < 0 && !isMaxClaimForThisDay && !isMaxClaim) {
                console.warn(`must feed animal ${animal.name} ${animal.asset_id} !!!`);
                if (isEgg) {
                    actions.push(this.createGameActions(this._contractName,
                        this._client.userAccount,
                        'anmclaim',
                        {
                            animal_id: animal.asset_id,
                            owner: this._client.userAccount,
                        }
                    ));    
                }
                else {
                    const barleyEaters = ["Chicken", "Calf"];  
                    if (barleyEaters.includes(animal.name)) {
                        const food = this.getFoodByName('Barley');
                        if (food) {
                            console.log(`${animal.name} will eat ${food.name} id:${food.asset_id}`);
                            if (this.canbuyToken()) {
                                const action = this.createGameActions(
                                    'atomicassets',
                                    this._client.userAccount,
                                    'transfer',
                                    {
                                        asset_ids: [food.asset_id],
                                        from: this._client.userAccount,
                                        to: 'farmersworld',
                                        memo: `feed_animal:${animal.asset_id}`
                                    }
                                );
                                actions.push(action);
                            }
                        }
                    }
                }
            }

        });
        if (actions.length !== 0) return actions;


        // check tools 
        const repairActions: TransactionAction[] = [];
        this._tools.forEach((tool) => {
            // check if need to repair
            // https://wax.bloks.io/transaction/0765085545e5e685d4f99318b0ef010f4eae45153afffbbf053786b904982389
            const numMembers = 4; // silver = 2
            const durability_consumed = tool.config?.durability_consumed! * (1  + numMembers);
            // console.log(`Tools ${tool.asset_id} current_durability:${tool.current_durability} durability_consumed:${tool.config?.durability_consumed}`)
            if (tool.current_durability <= durability_consumed) {
                const payed = (tool.durability - tool.current_durability) / 5;                
                const fwg = this._tokens.get('GOLD');
                if (fwg && fwg.value >= payed) {
                    console.warn(`Must repair tools ${tool.asset_id} ${tool.current_durability}/${tool.durability} payed:${payed}`);
                    const action = this.createGameActions(
                        this._contractName, 
                        this._client.userAccount,
                        'repair',
                        {
                            asset_id: tool.asset_id,
                            asset_owner: this._client.userAccount
                        }
                    );
                    repairActions.push(action);   
                }
            }

            const timeLeft = Math.round(tool.next_availability - Date.now() / 1000);
            // console.log(`tool ${tool.config?.schema_name} timeLeft: ${timeLeft}`);
            if (timeLeft < 0) {
                actions.push(
                    this.createGameActions(
                        FarmersWorldContract.NAME,
                        client.userAccount, 'claim', {
                        owner: client.userAccount,
                        asset_id: tool.asset_id
                    }
                    )
                );
            }
            
        });

        if (repairActions.length !== 0) return repairActions;
        if (actions.length !== 0) return actions;

        if (this._claimMember) {
            this._mbs.forEach((mbs) => {
                const timeLeft = Math.round(mbs.next_availability - Date.now() / 1000);
                // console.log(`mbs ${mbs.config?.name} timeLeft: ${timeLeft}`);
                if (timeLeft < 0 && this.account.energy > 100 && this._claimCoins) {
    
                    actions.push(
                        this.createGameActions(
                            FarmersWorldContract.NAME,
                            client.userAccount, 'mbsclaim', {
                            owner: client.userAccount,
                            asset_id: mbs.asset_id
                        }
                        )
                    );
                }
    
            });    
        }

        return actions;
        /*    
        if (actions.length !== 0) {
          console.log(`Some actions need to be done ! ${JSON.stringify(actions)}`);
          for (let idx in actions) {
            const action = actions[idx];
            await this.runTransaction(wax, action);
          }
          await this.reloadAssets(wax);
        }
        */
    }

    public async finalizeAction(action: any) {
        console.log('finalizeAction');
    }
    
    public handleRpcError(action:TransactionAction, error:RpcError): TransactionAction|null {
        console.error(`${this._name} get error = ${error.message}`);
        return null;
    }
}