import React from "react";
import { GameProps } from "../../../views/components/GameView";
import FarmersWorldContract from "../model/FarmersWorldcontract";
import TimeLeftView from "../../../views/components/TimeLeftView";
import { timeHourToString } from "../../../model/Tools";


const FWBotView: React.FunctionComponent<GameProps> = props => {
  const fw = props.game as FarmersWorldContract;
  
  const tools = fw.tools.map((tool) => {

    return (
      <tr key={tool.asset_id}>
        <td>{tool.config?.template_name}</td>
        <td>{tool.current_durability + '/' + tool.durability}</td>
        <td><TimeLeftView timeleft={fw.getTimeLeft(tool)}></TimeLeftView></td>
      </tr>
    )
  });

  const memberCards = fw.mbs.map((mb)=> {
    return (
      <tr key={mb.asset_id}>
        <td>{mb.config?.name}</td>
        <td><TimeLeftView timeleft={fw.getTimeLeft(mb)}></TimeLeftView></td>
      </tr>
    )
  });

  const buildings = fw.building.map((building) => {

    if (building.is_ready) {
      return (
        <tr key={building.asset_id}>
          <td className="td-name">{building.name}</td>
          <td className="td-claim"> <strong>{ building.times_claimed }</strong> /  { building.config?.required_claims} </td>
          <td className="td-time">Build</td>
        </tr>
      );
    }

    return (
      <tr key={building.asset_id}>
        <td className="td-name">{building.name}</td>
        <td className="td-claim"><strong>{ building.times_claimed }</strong> / { building.config?.required_claims}</td>
        <td className="td-time"><TimeLeftView timeleft={fw.getTimeLeft(building) }></TimeLeftView> </td>
      </tr>
    )
  });

  const crops = fw.crops.map((crop) => {
    return (
      <tr key={crop.asset_id}>
      <td className="td-name">{crop.name}</td>
      <td className="td-claim"><strong>{ crop.times_claimed }</strong> /  { crop.conf?.required_claims} </td>
      <td className="td-time"><TimeLeftView timeleft={fw.getTimeLeft(crop) }></TimeLeftView> </td>
    </tr> 
    )
  });

  const animals = fw.animals.map((animal) => {
    return (
      <tr key={animal.asset_id}>
        <td className="td-name">{animal.name} { timeHourToString(fw.getTimeForNextAnimalClaim(animal)) } </td>
        <td className="td-day"><strong>{ animal.day_claims_at.length }</strong> / { animal.conf?.daily_claim_limit}</td>
        <td className="td-claim"><strong>{ animal.times_claimed }</strong> / { animal.conf?.required_claims}</td>
        <td className="td-time"><TimeLeftView timeleft={fw.getTimeLeft(animal) }></TimeLeftView> </td>
      </tr> 
    )
  });



  return (
    <div>
      <div className="game-info">
        <p>Energy Left : <strong>{ fw.account.energy }</strong></p>
        <p>Withdraw fee: <strong>{fw.gameConfig.fee}%</strong></p>
      </div>
      <div className="farmersworld table-content">
        <h5>Tools</h5>
        <table className="styled-table">
          <thead>
            <tr>
              <td>Tools</td>
              <td>Durability</td>
              <td className="td-time">Time Left</td>                          
            </tr>
          </thead>
          <tbody>
            { tools}
          </tbody>
        </table>
        <h5>Membership ({ fw.claimMember ? "active":"desactived" })</h5>
        <table className="styled-table">
          <thead>
            <tr>
              <td>Name</td>
              <td className="td-time">Time Left</td>                          
            </tr>
          </thead>
          <tbody>
            { memberCards }
          </tbody>
        </table>

        <h5>Building</h5>
        <table className="styled-table">
          <thead>
            <tr>
              <td className="td-name">Name</td>
              <td className="td-claim">Claimed</td>              
              <td className="td-time">Time Left</td>                          
            </tr>
          </thead>
          <tbody>
            { buildings }
          </tbody>
        </table>

        <h5>Crops : <strong>{ fw.crops.length } / 8</strong></h5>
        <table className="styled-table">
          <thead>
            <tr>
              <td className="td-name">Name</td>
              <td className="td-claim">Claimed</td>
              <td className="td-time">Time Left</td>                          
            </tr>
          </thead>
          <tbody>
            { crops }
          </tbody>
        </table>

        <h5>
            Animals 
        </h5>
        <div className="game-info">
            <div>Barleys: <strong>{ fw.barleys.length }</strong></div>
        </div>
        <table className="styled-table">
          <thead>
            <tr>
              <td className="td-name">Name</td>
              <td className="td-day">Day</td>
              <td className="td-claim">Claimed</td>
              <td className="td-time">Time Left</td>                          
            </tr>
          </thead>
          <tbody>
            { animals }
          </tbody>
        </table>

      </div>


    </div>
  )
}

export default FWBotView;