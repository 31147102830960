import { AtomicAsset } from "../../../model/atomic/AtomicAssetClient";
import GameContract, { TransactionAction } from "../../../model/contract/GameContract";
import EOSClient, { prepareTableRequest } from "../../../model/RPCClient";
import { getCoin } from "../../../model/Tools";

/**
 * https://wax.bloks.io/account/xpansiongame
 * 
      xpansiongame
      renterclaim
        equipment_asset_id: 1099570258538
        owner: 1puqy.wam
        renter: qwnho.wam

      xpansiongame
        renterclaim
          equipment_asset_id: 1099570261835
          owner: qwnho.wam
          renter: qwnho.wam        

    xpansiongame - 
    claimrw
    land_id: 649613
    staker: qwnho.wam
  

SELL
* BMTL  https://wax.bloks.io/transaction/5672ebccabdafd411861fefcd4037f5a4a313690315ac8c58baeebafdddc242b
* LSM    https://wax.bloks.io/transaction/1a3bc029555526f2d049d2d6a269479e8acb503d5f8497f5e4d57337ff6b1f5b
* MNRL  https://wax.bloks.io/transaction/9aa3d89db2610bba1f333b9d72f8d6da8cdc6fd6bcdbff8e0a29c00451871d55
* OCP   https://wax.bloks.io/transaction/7590f80e6a2f715cd98a094943de8050ee0bd17749a5693f540c63625b887af7
* REE   https://wax.bloks.io/transaction/873e247b362f0b414ddfb6c1cb03b02104a505c07214bce5a8fcdfea65f1ca77
 * 
 * 
 * Repair
 * 
 * https://wax.bloks.io/transaction/e02f111f6e268096c4115c8dbcc120cbdd9b19dcca8ca36479b15232dccec664
 * 
 * repair rent
 * 
 * https://wax.bloks.io/transaction/75ac00d8edccaef5ce279954d62b2f83175b8f2e06c04ddd222bef92029980d8
 * -> table landclicks
 * 
 */

export interface LandSX {
  land_id: number,
  land_type: number,
  land_rarity: number,
  planet_id: number,
  x: number,
  y: number,
  owner: string,
  rent_fee: number,
  rent_list: string[]
}


export interface MiningAsset {
  asset_id: number,
  equipment_asset_id: string,
  land_id: number,
  land_rarity: number,
  land_type: number,
  mint_time: number,
  rent_fee: number,
  settlement_type: number,
  asset?: AtomicAsset,
  is_rented?:boolean,
}

export interface RentAsset extends MiningAsset {  
  renter: string,
  create_time: number,
}

export interface OwnerAsset extends MiningAsset {
  owner: string,
  rent_list: any[]
  rent_status: number,
}

export interface StakedLand {
  avai_balance: string,
  claim_time: number,
  land_id: number,
  stake_balance: string,
  stake_time: number
}

export interface EquipClick {
    click_count: number,
    equipment_asset_id: string,
    // XPS, BMTL, LSM, MNRL, OCP, REE
    resources: number[],
    total_click: number
}
// https://wax.bloks.io/transaction/e91f123fb37579dea920a291f03092bc277831bd7a6aa18e4004c917d2787b1d
// 
// click_count 84
// equipment_asset_id "1099596207303"
// [0, 0, 10752000, 10752000, 10752000, 0]
// 1384
// -> 84 -> 0, 0, 10.752, 10.752, 10.752,0


export default class XpansionContract extends GameContract {

  static NAME = 'xpansiongame';
  static CONTRACT_NAME = 'xpansiongame';
  static COLLECTION_NAME = 'xpansiongame';
  static TOKEN_CONTRACT = 'xpsgametoken';

  protected _coolDownTimeInSeconds: number = 3600;
  protected _rentAssets: RentAsset[];
  protected _ownAssets: OwnerAsset[];
  protected _stakedLands: StakedLand[];
  protected _stakedCooldDown: number = 60 * 60;
  protected _cpuSaveMode: boolean;
  protected _equipClicks: EquipClick[];

  constructor(client:EOSClient) {
    super(client);
    // console.log('[FarmersWorldContract] constructor');

    this._name = XpansionContract.NAME;
    this._tokenContract = XpansionContract.TOKEN_CONTRACT;
    this._contractName = XpansionContract.CONTRACT_NAME;
    this._collectionName = XpansionContract.COLLECTION_NAME;
    this._defaultSymbol = 'XPS';
    this._cpuSaveMode = true;
    this._equipClicks = [];
    
    if (this._cpuSaveMode) {
      console.warn('CPU save mode rented field is not harvest !');
    }

    this._rentAssets = [];
    this._ownAssets = [];
    this._stakedLands = [];

  }

  public loadAssets = async (): Promise<GameContract> => {
    await this.loadAtomicAssets();
    await this.loadOwnermint();
    await this.loadRentmint();
    await this.loadStakedLand();
    await super.loadAssets();
    await this.loadEquipClick();
    return this;
  }

  public updateAssets = async():Promise<GameContract> => {
    await this.loadAtomicAssets();
    await this.loadOwnermint();
    await this.loadRentmint();
    await this.loadStakedLand();
    await super.updateAssets();
    await this.loadEquipClick();
    return this;
  }  

  public get stakedLands() {
    return this._stakedLands;
  }

  public getTimeLeftForStakedLand(land:StakedLand) {
    return this._stakedCooldDown - Math.floor((new Date().getTime() / 1000) - land.claim_time);
  }

  protected async loadStakedLand() {
    const request = prepareTableRequest({
      code: this._contractName,
      scope: this._client.userAccount,
      table: 'staker'
    })
    try {
      const result = await this.requestTable(request);
      const rows = result!['rows'];
      this._stakedLands = rows;
    }
    catch (e) {
      console.error(e);
    }
    
  }

  public async autoActions(): Promise<TransactionAction[]> {
    await this.updateAssets();
    const client = this._client;
    const actions: TransactionAction[] = [];


    const equipementsToRepairs:EquipClick[] = [];
    this._equipClicks.forEach((e)=> {
        if (e.click_count >= 60) {
            equipementsToRepairs.push(e);
        }
    });

    if (equipementsToRepairs.length !== 0) {
        console.log('Must repair !');
        /*
        const assets = equipementsToRepairs.map((e)=> {
            return e.equipment_asset_id;
        });
        actions.push(this.createGameActions(
            XpansionContract.NAME,
            client.userAccount,
            "repaireqs",
            {
                equip_asset_ids: assets,
                owner: client.userAccount,
            }          
          ));          
        console.log(actions);
        //actions.push(this.createGameActions(
        return actions;
        */
    }
    
    const ownedReady: number[] = [];

    // check if land owned can be harvested 
    for (let asset of this._ownAssets) {
      const timeLeft = this.getTimeLeft(asset);
      if (timeLeft < 0) {
        ownedReady.push(asset.land_id);
      }
    }
    // 
    if (ownedReady.length === this._ownAssets.length) {
      actions.push(this.createGameActions(
        XpansionContract.NAME,
        client.userAccount,
        "ownerclaim",
        {
          land_ids: ownedReady,
          owner: client.userAccount,
        }          
      ));  
    }

    // check if land rented can be harvested 
    // const assetToClaims: RentAsset[] = [];
    for (let asset of this._rentAssets) {
    const timeLeft = this.getTimeLeft(asset);
    const isSat = asset.land_rarity === 0;
    if (timeLeft < 0) {
        if (isSat && this._cpuSaveMode) {
            console.warn('Desactivate Rent Satelite for CPU !!!');
        }
        else {
            //assetToClaims.push(asset);
            // const landSX = await this.loadLandSXById(asset.land_id);
            //const owner = landSX?.owner === '' ? client.userAccount:landSX?.owner;
            actions.push(this.createGameActions(
            XpansionContract.NAME,
            client.userAccount,
            "renterclaim",
            {
                equipment_asset_ids: [asset.equipment_asset_id],
                /*owner: owner,*/
                renter: client.userAccount
            }          
            ));  
        }
    }
      /*
      if (assetToClaims.length !== 0) {
        const all = assetToClaims.map((asset) => asset.asset_id);
        actions.push(this.createGameActions(
          XpansionContract.NAME,
          client.userAccount,
          "renterclaim",
          {
            equipment_asset_ids: all,
            renter: client.userAccount
          }          
        ));
      }
      */
    }

    // check 
    for (let staked of this._stakedLands) {
      const timeLeft = this.getTimeLeftForStakedLand(staked);
      const balance = getCoin(staked.stake_balance);
      if (timeLeft < 0 && balance.value !== 0) {
        actions.push(this.createGameActions(
          XpansionContract.NAME,
          client.userAccount,
          "claimrw",
          {
            land_id: staked.land_id,
            staker: this._client.userAccount
          }          
        ));  
      }
    }  

    return actions;
  }

  public get rentAssets() {
    return this._rentAssets;
  }

  public get ownAssets() {
    return this._ownAssets;
  }

  public getTimeLeft(asset:MiningAsset) {
    let coolDown = this._coolDownTimeInSeconds;
    if (asset.is_rented && asset.land_rarity !== 0) {
      coolDown = this._coolDownTimeInSeconds * 6; // use time clicker only 
    }
    const time = asset.mint_time;
    const now = new Date().getTime() / 1000;
    const dt = (time + coolDown)-now;
    return Math.round(dt);
  }


  protected async loadEquipClick() {
    // crypto-random-string
    const request = prepareTableRequest({
        code: this._contractName,
        scope: this._client.userAccount,
        table: 'equipclick'
      })
      try {
        const result = await this.requestTable(request);
        const rows = result!['rows'];
        this._equipClicks = rows;
        console.log(this._equipClicks);
     }
      catch (e) {
        console.error(e);
      }    
  }

  protected async loadOwnermint() {
    try {
      const results = await this._client.rpc.get_table_rows({
        json: true,
        code: XpansionContract.CONTRACT_NAME,
        scope: this._client.userAccount,
        table: 'ownermint',
        limit: 4000,
        reverse: false,
        show_payer: false
      });
      const rows = results['rows'];
      this._ownAssets = rows;
      this._ownAssets.forEach((e) => {
        e.is_rented = false;
      })

    }
    catch (e) {
      console.error(e);
    }
  }

  /**
   */
  protected async loadRentmint() {
        // qwnho.wam
        const client = this._client;
        try {
          const results = await client.rpc.get_table_rows({
            json: true,
            code: XpansionContract.CONTRACT_NAME,
            scope: client.userAccount,
            table: 'rentmint',
            limit: 4000,
            reverse: false,
            show_payer: false
          });
          const rows = results['rows'];
          this._rentAssets = rows;
          this._rentAssets.forEach((e) => {
            e.is_rented = true;
          })
          // table landsx -> land id 
          // land_id (key) planet_id	x	y	owner	asset_id	settlement_type	land_rarity	land_type	rent_fee	rent_status	rent_list	stake_tax	stake_tax_update	stake_tax_duration	stakers	avai_balance	stake_balance	claim_time	stake_time
          /*
          const atomicClient = new AtomicAssetClient();
          for (let asset of this._rentAssets) {
            if (asset.asset_id !== 0) {
              console.log(`must get owner for ${asset.asset_id}`);
              //   //   https://wax.api.atomicassets.io/atomicassets/v1/assets?ids=1099586858971,0&order=desc&sort=asset_id&collection_name=xpansiongame&schema_name=lands.kl22b&limit=20000
              const params: AtomicAssetParam = {
                ids: [asset.asset_id, 0].join(','),
                collection_name: 'xpansiongame',
                schema_name: 'lands.kl22b',
                limit: 20000,
                order: 'desc',
                sort: 'asset_id'
              }
              const assets = await atomicClient.loadAssets(params);
              console.log(assets);
            }
          }
          */
        }
        catch (e) {
          console.error(e);
        }   
  }

  public async loadLandSXById(land_id:number):Promise<LandSX|undefined> {
    try {
      const results = await this._client.rpc.get_table_rows({
        json: true,
        code: XpansionContract.CONTRACT_NAME,
        scope: XpansionContract.CONTRACT_NAME,
        table: 'landsx',
        lower_bound: land_id,
        limit: 1,
        reverse: false,
        show_payer: false
      });
      const rows = results['rows'];
      return rows[0];
    }
    catch (e) {
      console.error(e);
    }   
    return;
  }
  
}