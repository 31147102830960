import React from "react";
import { GameProps } from "../../../views/components/GameView";
import AnimalWorldContract from "../model/AnimalWorldContract";
import TimeLeftView from "../../../views/components/TimeLeftView";

const ANWBotView: React.FunctionComponent<GameProps> = props => {
  const contract = props.game as AnimalWorldContract;
  const timeLeft = contract.getTimeLeft(contract.user.data![0].last_claim);
  return (
    <div className="table-content animalworld">      
      <table className="styled-table">
        <tbody>
          <tr>
            <td>Harvest</td>
            <td className="td-timeleft"><TimeLeftView timeleft={timeLeft}></TimeLeftView></td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export default ANWBotView;