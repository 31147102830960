import React from "react";
import { observer } from "mobx-react-lite";
import UserStore from "../../model/UserStore";

const Login = observer((): React.ReactElement => {

  // qwnho.wam hboiy.wam
  const handleLogin = async () => {
    await UserStore.getInstance().login();
  };

  return (
    <div className="container login">
      <div>
        <p>Welcome to Spice a bot manager for WAX Games</p>
        <button onClick={handleLogin}>Login</button>
      </div>
      <footer>
        { process.env.REACT_APP_VERSION }
      </footer>
    </div>
  );
});

export default Login;

/*
      <div className="login-simulator">
        <input type="text" value={ userAccount } onChange={ handleChangeLogin }></input>
        <button onClick={handleSimpleLogin}>Send</button>
      </div>
*/