import { timeHourToString } from "../../model/Tools";

export interface TimeLeftProps  {
  timeleft:number
}

const TimeLeftView: React.FunctionComponent<TimeLeftProps> = props => {
  let className = '';
  switch(true) {
    case props.timeleft > 10*60:
      className = 'time-far'
      break;
    case props.timeleft > 0 && props.timeleft < 10*60:
      className = 'time-near'
      break;
    case props.timeleft < 0:
      className = 'time-ready'
      break;
  };
  return <>
    { props.timeleft > 0 
        ? <span className={"timeleft "+className}>{ timeHourToString(props.timeleft) }</span>
        : <span className={"timeleft "+className}>ready</span>
    }
  </>
  
}

export default TimeLeftView;