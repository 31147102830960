import React from "react";
import { GameProps } from "../../../views/components/GameView";
import CenturyTrainContract from "../model/CenturyTrainContract";
import TimeLeftView from "../../../views/components/TimeLeftView";

const CTBotView: React.FunctionComponent<GameProps> = props => {
  const ct = props.game as CenturyTrainContract;

  const handleBuyDiesel = async () => {
    const action = ct.createBuyDieselAction();
    await action.game.runTransaction(action);
  }

  const railRunsView = ct.railruns.map((railrun) => {
    const timeLeft = ct.getTimeLeft(railrun);
    let fromName = railrun.depart_station;
    let toName = railrun.arrive_station;
    
    const fromStation = ct.getStationById(railrun.depart_station);
    if (fromStation) {
       fromName = fromStation.config ? fromStation.config.mutable_data.station_name : fromStation.station_id;
    }
    const toStation = ct.getStationById(railrun.arrive_station);
    if (toStation) {
        toName = toStation.config ? toStation.config.mutable_data.station_name : toStation.station_id;
    }
    
    return (
          <tr key={railrun.train}>
            <td className="train">{ railrun.train }</td>
            <td className="from">{ fromName }</td>
            <td className="to">{ toName }</td>
            <td className="td-timeleft"><TimeLeftView timeleft={timeLeft}></TimeLeftView></td>
          </tr>
    )
  });
  return (
    <div className="table-content centurytrain">      
      <table className="styled-table">
        <thead>
          <tr>
            <td className="train">Train</td>
            <td className="from">From</td>
            <td className="to">To</td>
            <td className="td-timeleft">Timeleft</td>
          </tr>
        </thead>
        <tbody>
          { railRunsView }
        </tbody>
      </table>
      <button className="btn" onClick={handleBuyDiesel}>Buy Diesel</button>
    </div>
  )
}

export default CTBotView;