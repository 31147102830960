import { AnnotationsMap, makeObservable, observable, runInAction } from "mobx";
import Asset from "../../../model/atomic/Asset";
import GameContract, { HarvestAsset, TransactionAction } from "../../../model/contract/GameContract";
import EOSClient, { prepareTableRequest } from "../../../model/RPCClient";
import UserStore from "../../../model/UserStore";

/*
Contract 
* https://wax.bloks.io/account/farminggames

code: "farminggames"
index_position: 1
json: true
key_type: ""
limit: 1
lower_bound: "qwnho.wam"
reverse: false
scope: "farminggames"
show_payer: false
table: "resources"
table_key: ""
upper_bound: "qwnho.wam"


by food
farmingtoken
transfer

from: qwnho.wam
to : farminggames  
memo: food_refill
*/

export interface Resource {
  food: number,
  food_max: number,
  owner: string,
  silo_id: string,
  silo_template_id:string,
  water: number,
  water_max: number,
  water_template_id: string,
  water_tower_id: string,
}

export interface BuilingdConf {
  template_id: string,
  label: string,
  animal_templates: string[],
};

export interface Building {
  asset_id: string,
  owner: string,
  template_id: string,
  config?: BuilingdConf
}

export interface HarvestItemConfig {
  cooldown: number,
  sest_amount: number,
  food?:number,
  water?:number,
}

export interface HarvestItem {
  last_harvest: number,
  owner: string,
  config?: HarvestItemConfig
}

export interface AnimalConf extends HarvestItemConfig {
  template_id: string,
  label: string,
  resource_type: string,
  resource_count: string,
  food: number,
};

export interface Animal extends HarvestItem {
  asset_id: string,
  building_asset_id: string,
  template_id: string
  config?: AnimalConf
}

export interface Plantconf extends HarvestItemConfig {
  id: number,
  label: string,
  resource_count: number,
  resource_type: string,
  water: number
}

export interface Plant extends HarvestItem {
  building_asset_id: string,
  id: number,
  owner: string,
  plant_id: number,
  config?: Plantconf
}

export default class FarmingTaleContract extends GameContract {

  static NAME = 'farminggames';
  static CONTRACT_NAME = 'farminggames';  
  static COLLECTION_NAME = 'farmingtales' 
  static TOKEN_CONTRACT = 'farmingtoken'
  static FOOD_REFILL = 'refillfood';
  static WATER_REFILL = 'refillwater';

  private _animals: Animal[] = [];
  private _animalsConf: Map<string, AnimalConf>;

  protected _builds: Building[] = [];
  protected _buildsConf: Map<string, BuilingdConf>;

  private _plants: Plant[] = [];
  private _plantsConf: Map<string, Plantconf>;

  protected _all: Asset[] = [];
  protected _toClaim: number = 0;
  protected _tokenBalance: number = 0;
  protected _resource: Resource;

  constructor(client:EOSClient) {
    super(client);
    // console.log('[FarmingTaleContract] constructor');
    this._name = FarmingTaleContract.NAME;
    this._contractName = FarmingTaleContract.NAME;
    this._tokenContract = FarmingTaleContract.TOKEN_CONTRACT;
    this._defaultSymbol = 'SEST';

    this._animalsConf = new Map();
    this._buildsConf = new Map();
    this._plantsConf = new Map();

    makeObservable(this, {
      _toClaim: observable,
      _tokenBalance: observable,
    } as AnnotationsMap<this, string>);

    this._resource = {
      water_max: 100,
      water: 0,
      food: 0,
      food_max: 100,
      water_template_id: '',
      water_tower_id: '',
      silo_id: '',
      silo_template_id: '',
      owner: ''
    }

  }

  public loadAssets = async () => {
    //
    this._animalsConf = await this.loadConfAnimals();
    // 
    this._animals = await this.loadAnimals();
    // console.log(`Found ${this._animals.length} animals for ${store.wax.userAccount}`);

    this._buildsConf = await this.loadConfBuilds();
    //
    this._builds = await this.loadBuilds();
    // console.log(`Found ${this._builds.length} builds for ${store.wax.userAccount}`);
    // 
    await this.loadPlantConfigs();
    // 
    await this.loadPlants();

    await this.loadResources();

    // await this.getBalanceToClaim();

    this._all = UserStore.getInstance().getAssetsByCollection(FarmingTaleContract.COLLECTION_NAME);
    // console.log(this._all);

    await super.loadAssets();

    return this;
  }

  public updateAssets = async() => {
    this._animals = await this.loadAnimals();
    this._builds = await this.loadBuilds();
    await this.loadPlants();
    // await this.getBalanceToClaim();
    await this.loadResources();

    await super.updateAssets();

    return this;
  }  

  public get resource(): Resource {
    return this._resource;
  }

  public get plants() {
    return this._plants;
  }

  public getTimeLeft(item:HarvestItem): number {
      const t = Date.now() / 1000 - item.last_harvest;
      if (item.config) {
          return Math.round(item.config.cooldown - t);
      }
      return 5000000;
  }

  protected async getBalanceToClaim() {
    try {
        const client = this._client;
        const results = await client.rpc.get_table_rows({
          json: true,
          code: FarmingTaleContract.NAME,
          scope: 'farminggames',
          table: 'claim',
          lower_bound: client.userAccount,
          limit: 1,
          reverse: false,
          show_payer: false
        });
        const data = results['rows'][0];
        runInAction(()=>{
          this._toClaim = parseInt(data['to_claim']) / 100000;
        })    
    }     
    catch (e) {
        // console.error(e);
    } 
  }

  public async loadBalances(symbol: string = '') {
    await super.loadBalances(symbol);
    const request = prepareTableRequest({
      code: FarmingTaleContract.CONTRACT_NAME,
      scope: FarmingTaleContract.CONTRACT_NAME,
      table: 'wallet',
      lower_bound: this._client.userAccount,
      upper_bound: this._client.userAccount,
    });
    try {
      const result = await this.requestTable(request);
      const rows = result!['rows'] as any;
      rows[0]['balances'].forEach((token:any) => {
        const symbol = token['key']+'_G';
        this._tokens.set(symbol, {
          symbol: symbol,
          value: token['value']/10000
        });
      });
    }
    catch (e) {
      console.error(e);
    }
  }

  protected loadResources = async (): Promise<any> => {
    try {
      const results = await this._client.rpc.get_table_rows({
        json: true,
        code: FarmingTaleContract.NAME,
        scope: 'farminggames',
        table: 'resources',
        lower_bound: this._client.userAccount,
        upper_bound: this._client.userAccount,
        limit: 1,
        reverse: false,
        show_payer: false
      });
      const rows = results['rows'];
      this._resource = rows[0];
      // console.log(this._resource);
    }
    catch (e) {
      console.error(e);
    }
  }

  protected loadBuilds = async (): Promise<Building[]> => {
    const client = this._client;
    const builds: Building[] = [];
    try {
      const results = await client.rpc.get_table_rows({
        json: true,
        code: FarmingTaleContract.NAME,
        scope: 'farminggames',
        table: 'building',
        index_position: 2,
        key_type: 'name',
        lower_bound: client.userAccount,
        upper_bound: client.userAccount,
        limit: 50,
        reverse: false,
        show_payer: false
      });
      const rows = results['rows'];
      rows.forEach((row) => {
        // console.log(row);
        const owner: string = row['owner'];
        if (owner === client.userAccount) {
          /*
          const asset = new FarmingTaleAsset(row['asset_id']);
          asset.owner = row['owner'];
          asset.templateId = row['template_id'];
          */
          const asset:Building = {
            asset_id: row['asset_id'],
            owner: row['owner'],
            template_id: row['template_id'],
          } 
          if (this._buildsConf.has(asset.template_id)) {
            asset.config = this._buildsConf.get(asset.template_id);
          }
          builds.push(asset);
        }
      });
    }
    catch (e) {
      console.error(e);
    }
    return builds;
  }

  public async loadPlants() {
    const request = prepareTableRequest({
      code: 'farminggames',
      scope: 'farminggames',
      table: 'plant',
      index_position: 2,
      key_type: 'name',
      lower_bound: this._client.userAccount,
      upper_bound: this._client.userAccount,
    })
    const result = await this.requestTable(request);
    console.log(result);
    if (result) {
      this._plants = result['rows'];
      

      this._plants.forEach((plant) => {
        if (this._plantsConf.has(plant.plant_id.toString())) {
          plant.config = this._plantsConf.get(plant.plant_id.toString());
        }
      })
    }
  }

  public async loadPlantConfigs() {
    const request = prepareTableRequest({
      code: 'farminggames',
      scope: 'farminggames',
      table: 'confplant'
    })

    const result = await this.requestTable(request);
    if (result) {
      const rows = result['rows'];
      rows.forEach((row) => {
        this._plantsConf.set(row['id'].toString(), row);
      })
    }   
  }

  public loadAnimals = async (): Promise<Animal[]> => {
    const animals: Animal[] = [];
    try {
      const client = this._client;
      const results = await client.rpc.get_table_rows({
        json: true,
        code: 'farminggames',
        scope: 'farminggames',
        table: 'animal',
        index_position: 2,
        key_type: 'name',
        lower_bound: client.userAccount,
        limit: 50,
        reverse: false,
        show_payer: false
      });
      const rows = results['rows'];
      rows.forEach((row) => {
        // console.log(row);
        const owner: string = row['owner']; 
        if (owner === client.userAccount) {
          const asset: Animal = {
            asset_id: row['asset_id'],
            owner: row['owner'],
            template_id: row['template_id'],
            building_asset_id: row['building_asset_id'],
            last_harvest: row['last_harvest'],
          }
          if (this._animalsConf.has(asset.template_id)) {
            asset.config = this._animalsConf.get(asset.template_id);
          }
          animals.push(asset);
        }
      });
  
    }
    catch (e) {
      console.error(e);

    }
    return animals;
  }

  protected getAnimalConfig(template_id: string) {
    this._animalsConf.forEach((a) => {
      if (a.template_id === template_id) {

      }
    })
  }

  public loadConfAnimals = async (): Promise<Map<string, AnimalConf>> => {
    const client = this._client;
    const configurations: Map<string, AnimalConf> = new Map<string, AnimalConf>();
    try {
      const results = await client.rpc.get_table_rows({
        json: true,
        code: FarmingTaleContract.NAME,
        scope: 'farminggames',
        table: 'confanimal',
        limit: 100,
        reverse: false,
        show_payer: false
      });
      const rows = results['rows'];
      rows.forEach((row) => {
        configurations.set(row['template_id'], row);
      });
    }
    catch (e) {
      console.error(e);
    }
    return configurations;
  }

  public async loadConfBuilds() {
    const client = this._client;
    const configurations: Map<string, BuilingdConf> = new Map<string, BuilingdConf>();
    try {
      const results = await client.rpc.get_table_rows({
        json: true,
        code: FarmingTaleContract.NAME,
        scope: 'farminggames',
        table: 'confbuilding',
        limit: 100,
        reverse: false,
        show_payer: false
      });
      const rows = results['rows'];
      rows.forEach((row) => {
        configurations.set(row['template_id'], row);
      });
    }
    catch (e) {
      console.error(e);
    }
    return configurations;
  }

  public get animals(): Animal[] {
    return this._animals;
  }

  public updateLastHarvest = async (client: EOSClient, animal: Animal) => {
    try {
      const assets = await client.rpc.get_table_rows({
        json: true,
        code: FarmingTaleContract.NAME,
        scope: "farminggames",
        table: "animal",
        lower_bound: animal.asset_id,
        limit: 1,
        reverse: false,
        show_payer: false,
      });
      const resultAnimal = assets.rows[0];
      animal.last_harvest = resultAnimal['last_harvest'];
    }
    catch (e) {
      console.error(e);
    }
  }

  public async listHarvest(): Promise<HarvestAsset[]> {
    const list: HarvestAsset[] = [];
    this._animals.forEach((animal) => {
      list.push({
        id: animal.asset_id,
        label: animal.config?.label!,
        timeLeft: 0,
        collectionName: FarmingTaleContract.COLLECTION_NAME,
      })
    })
    return list;
  }

  public createBuyResourceAction(actionName:string) {
    // food_refill water_refill
    const action = this.createGameActions(
      FarmingTaleContract.CONTRACT_NAME,
      this._client.userAccount,
      actionName,
      {
        account: this._client.userAccount
      }          
    );
    return action;
  }

  public async autoActions(): Promise<TransactionAction[]> {
    
    await this.updateAssets();

    const actions: any[] = [];
    const client = this._client;

    // check all gardens 

    let buyWater = false;
    const mmaintenanceMode = true;
    if (!mmaintenanceMode) {
        this._plants.forEach(async (garden)=> {
            console.log(garden);
          const timeLeft = this.getTimeLeft(garden);
          if (timeLeft < 0) {
            // check if enough water
            const water = garden.config?.water;
            if (water && water > this._resource.water) {
              console.warn('Must buy some water before harvest');
              buyWater = true;
            } else {
              actions.push(this.createGameActions(
                FarmingTaleContract.NAME,
                client.userAccount,
                "harvestplant",
                {
                  account: client.userAccount,
                  plant_id: garden.building_asset_id,
                }          
              ));  
            }
          }
        });
    } 
    else {
        console.warn('Maintenance mode ! no plants !!!');
    }

    // check all animals 
    let buyFood = false;
    this._animals.forEach(async (animal) => {
      const timeLeft = this.getTimeLeft(animal);
      // console.log(`animal ${animal.config?.label} timeLeft: ${timeLeft}`);
      if (timeLeft < 0) {
        // check if enough food
        const food = animal.config?.food;
        // console.warn(this._resource.food);
        if (food && food > this._resource.food) {
            console.warn('Must buy some food before harvest');
            buyFood = true;           
        }
        else {
          actions.push(this.createGameActions(
            FarmingTaleContract.NAME,
            client.userAccount,
            "harvestanim",
            {
              account: client.userAccount,
              asset_id: animal.asset_id,
            }          
          ));  
        }
      }
    });

    if (buyFood && this.canbuyToken()) {
      actions.push(this.createBuyResourceAction(FarmingTaleContract.FOOD_REFILL));
    }
    
    if (buyWater && this.canbuyToken()) {
      actions.push(this.createBuyResourceAction(FarmingTaleContract.WATER_REFILL));
    }

    if (this._toClaim !== 0) {
      actions.push(this.createGameActions(
        FarmingTaleContract.NAME,
        client.userAccount,
        "claim",
        {
          account: client.userAccount,
        }          
      ));
    }

    return actions;
    /*
    if (actions.length !== 0) {
      console.log(`Some actions need to be done ! ${JSON.stringify(actions)}`);
    }
    */
  }

  public get builds() {
    return this._builds;
  }

  public getAnimalsForBuild(build:Building) {
    const animals:Animal[] = [];
    this._animals.forEach((animal)=> {
      if (animal.building_asset_id === build.asset_id) {
        animals.push(animal);
      }
    })
    return animals; 
  }

}

