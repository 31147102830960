import React from "react";
import { GameProps } from "../../../views/components/GameView";
import FarmingTaleContract from "../model/FarmingTaleContract";
import TimeLeftView from "../../../views/components/TimeLeftView";

const GardensView : React.FunctionComponent<GameProps> = props => {
  const ft = props.game as FarmingTaleContract;
  const gardens = ft.plants.map((plant)=> {
    const timeLeft = ft.getTimeLeft(plant);
    return (
      <tr key={plant.building_asset_id}>
        <td className="td-name">{plant.config?.label}</td>
        <td className="td-water">{plant.config?.water}</td>
        <td className="td-sest">{plant.config?.sest_amount! / 10000}</td>
        <td className="td-time"><TimeLeftView timeleft={timeLeft}></TimeLeftView></td>
      </tr>
    )
    
  })
  return (
    <div className="table-content">
      <table className="styled-table farmingtales">
        <thead>
          <tr>
            <td className="td-name">Name</td>
            <td className="td-water">Water</td>
            <td className="td-sest">SEST</td>
            <td className="td-time">Timeleft</td>
          </tr>
        </thead>
        <tbody>      
        { gardens }
        </tbody>
      </table>
     
    </div>
  );
}

const FTBotView: React.FunctionComponent<GameProps> = props => {
  const ft = props.game as FarmingTaleContract;
  
  const handleBuyFood = async () => {
    const action = ft.createBuyResourceAction(FarmingTaleContract.FOOD_REFILL);
    await action.game.runTransaction(action);
  }
  const handleBuyWater = async () => {
    const action = ft.createBuyResourceAction(FarmingTaleContract.WATER_REFILL);
    await action.game.runTransaction(action);
  }
  
  const builds = ft.builds.map((build)=> {
    const animals = ft.getAnimalsForBuild(build);
    
    const animalsView = animals.map((a)=> {
      const timeLeft = ft.getTimeLeft(a);
      return (
        <tr key={a.asset_id}>
          <td className="small td-name">{a.config!.label}</td>
          <td className="td-food">{a.config?.food}</td>
          <td className="td-sest">{a.config?.sest_amount!/10000}</td>
          <td className="td-time"><TimeLeftView timeleft={timeLeft}></TimeLeftView></td>
        </tr>
      )
    });

    if (animals.length === 0) {
      return <div key={build.asset_id}></div>
    }

    return (
      <div key={build.asset_id+'-table'} className="table-content">
        <h5>{ build.config?.label }</h5>
        <table key={build.asset_id+'-table'} className="styled-table farmingtales">
          <thead>
            <tr>
              <td className="td-name">Name</td>
              <td className="td-food">Food</td>
              <td className="td-sest">SEST</td>
              <td className="td-time">Timeleft</td>
            </tr>
          </thead>
          <tbody>      
            { animalsView }
          </tbody>
        </table>
      </div>
    )
  });




  const contract = props.game as FarmingTaleContract;
  return (
    <div className={props.game.name+" table-content"}>
      <div className="game-info">
        <p>Food : <strong>{ contract.resource.food + ' / ' + contract.resource.food_max }</strong></p>
        <p>Water: <strong>{ contract.resource.water + ' / ' + contract.resource.water_max }</strong></p>
      </div>
      <h3>Buildings</h3>
      { builds }
      <h3>Gardens</h3>
      <GardensView game={props.game}></GardensView>
      <button className="btn" onClick={handleBuyFood}>Buy Food</button>
      <button className="btn" onClick={handleBuyWater}>Buy Water</button>
    </div>
  )
}

export default FTBotView;