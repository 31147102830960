import { observer } from "mobx-react-lite";
import GameContract, { TokenBalance } from "../../model/contract/GameContract";
import FarmersWorldContract from "../../games/farmersworld/model/FarmersWorldcontract";
import FWBotView from "../../games/farmersworld/view/FWBotView";
import FarmingTaleContract from "../../games/farmingtales/model/FarmingTaleContract";
import FTBotView from "../../games/farmingtales/view/FTBotView";
import CenturyTrainContract from "../../games/centurytrain/model/CenturyTrainContract";
import CTBotView from "../../games/centurytrain/view/CTBotView";
import AlienWorldsContract from "../../games/alienworlds/model/AlienWorldsContract";
import AWBotView from "../../games/alienworlds/view/AWBotView";
import NftPandaContract from "../../games/nftpandawofg/model/NftPandaContract";
import NftPandaBotView from "../../games/nftpandawofg/view/NftPandaBotView";
import AnimalWorldContract from "../../games/animalworld/model/AnimalWorldContract";
import XpansionContract from "../../games/xpansion/model/XpansionContract";
import XPBotView from "../../games/xpansion/view/XPBotView";
import ANWBotView from "../../games/animalworld/view/ANWBotView";
import GameBot from "../../model/GameBot";
import { useTranslation } from "react-i18next";
import OfficeLandContract from "../../games/oficeland/model/OfficeLandContract";
import OLBotView from "../../games/oficeland/view/OLBotView";
import CrittercraftContract from "../../games/crittercraft/model/CrittercraftContract";
import CrittercraftBotView from "../../games/crittercraft/view/CrittercraftBotView";

export interface GameBotProps  {
  bot: GameBot
}

export interface BallanceProps {
  tokens: TokenBalance[]
}
    
const BalanceView = (props:BallanceProps): React.ReactElement => {
  const tokens = props.tokens;
  const items = tokens.map((e)=> {
    return <li key={e.symbol}>{e.symbol}: <span>{e.value.toFixed(2)}</span></li>
  })
  return (
    <div className="ballance">
      <ul>
        {items}
      </ul>    
    </div>
  )
}
/*
const MyStatelessComponent : React.FunctionComponent<MyProps> = props =>
    <div>
        <p>{props.propInMyProps}</p>
        <p>{props.children}</p>
    </div>
*/

function getChidren(game:GameContract) {
  switch(game.name) {
    case FarmersWorldContract.NAME:
      return <FWBotView game={game}></FWBotView>
    case FarmingTaleContract.NAME:
      return <FTBotView game={game}></FTBotView>
    case CenturyTrainContract.NAME:
      return <CTBotView game={game}></CTBotView>
    case AlienWorldsContract.NAME:
      return <AWBotView game={game}></AWBotView>
    case NftPandaContract.NAME:
      return <NftPandaBotView game={game}></NftPandaBotView>
    case AnimalWorldContract.NAME:
      return <ANWBotView game={game}></ANWBotView>
    case XpansionContract.NAME:
      return <XPBotView game={game}></XPBotView>
    case OfficeLandContract.NAME:
      return <OLBotView game={game}></OLBotView>
    case CrittercraftContract.NAME:
      return <CrittercraftBotView game={game}></CrittercraftBotView>
    default:
      return <div>Special Content for game {game.name}</div>
  }
}

const BotView: React.FunctionComponent<GameBotProps> = props => {
  const { bot } = props;  
  const { t } = useTranslation();
  const gameName = t([`games.${bot.game.name}.title`]);
  return (    
      <div className="bot">       
        { bot.isReady 
          ? <div>
              <h1>{ gameName } <span>({ bot.game.lastUpdatedAtString })</span></h1>
              { bot.game.tokens.length !== 0 && <BalanceView tokens={ bot.game.tokens}></BalanceView> }
              { getChidren(bot.game) }
            </div>
          :
            <div className="loading">Loading: <strong>{ gameName }</strong></div>
        }
      </div>
  )
}


export default observer(BotView);