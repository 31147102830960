import React from "react";
import { observer } from "mobx-react-lite";
import UserStore from "../../model/UserStore";
import { Link, useLocation } from "react-router-dom";

const Footer = observer((): React.ReactElement => { 
  const userStore = UserStore.getInstance();
  const { pathname } = useLocation();

  const items = [
    ['/', 'Home'],
    ['/bot', 'Bot'],
    ['/games', 'Games'],
    ['/settings', 'Settings'],
  ];

  const tabBar = items.map((e)=> {
    const active = e[0] === pathname;
    return <Link key={e[0]} to={e[0]} className={active?'active':'' && "bottom-nav item"}>{e[1]}</Link>
  })


  return <footer className="footer">
          { userStore.isLogged() 
                  ? 
                  <div className="bottom-nav">
                    { tabBar }                    
                  </div>
                     
                  : ''}
    </footer>
});

export default Footer;