import React from 'react';
import { observer } from "mobx-react-lite";
import { Route, Routes, BrowserRouter } from "react-router-dom";

import Home from './views/pages/Home';
import Login from './views/pages/Login';
import Bots from './views/pages/Bots';

import Header from './views/components/Header';

import FarmingTaleView from './games/farmingtales/view/FarmingTaleView';
import FarmersWorldView from './games/farmersworld/view/FarmersWorldView';
import UserStore from "./model/UserStore";
import Footer from './views/components/Footer';

import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import translations from './translations/translations.json'

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    resources: translations,
    lng: "en", // if you're using a language detector, do not define the lng option
    fallbackLng: "en",

    interpolation: {
      escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    }
  });

  

// const App = observer(({ skynet, connectivityProvider, debugComponents, history = createBrowserHistory() }: AppProps): React.ReactElement => {
const App = observer((): React.ReactElement => {
  // const userStore = UserStore.getInstance()
  // useEffect(() => {
  //   userStore.login();
  // }, [])

  return (
    <BrowserRouter>
      <Header />
      { UserStore.getInstance().isLogged() ?
      <Routes>
        <Route path="/" element={<Home></Home>} />
        <Route path="/bot" element={<Bots></Bots>} />
        <Route path="users/*" element={<Login></Login>} />
        <Route path="ft/*" element={<FarmingTaleView></FarmingTaleView>} />
        <Route path="fw/*" element={<FarmersWorldView></FarmersWorldView>} />
      </Routes>
      :
      <Login></Login>
      }
      <Footer></Footer>
  </BrowserRouter>

  )
});

export default App;

