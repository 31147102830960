import React from "react";
import { GameProps } from "../../../views/components/GameView";
import XpansionContract, { MiningAsset } from "../model/XpansionContract";
import TimeLeftView from "../../../views/components/TimeLeftView";
import GameContract from "../../../model/contract/GameContract";
import { getCoin } from "../../../model/Tools";

interface TableProps {
  game: GameContract,
  assets: MiningAsset[],
  name: string,
}

const LandTableView: React.FunctionComponent<TableProps> = props => {
  const contract = props.game as XpansionContract;
  const assets = props.assets.map((e) => {
    const timeLeft = contract.getTimeLeft(e);
    const rarity = ['sat', 'comon', 'uncomon', 'rare', 'epic', 'legend'];
    return (
      <tr key={e.asset_id + '_' + e.equipment_asset_id}>
        <td className="td-land">{rarity[e.land_rarity]} - {e.rent_fee }% </td>
        <td className="td-equip">{e.equipment_asset_id}</td>
        <td className="td-timeleft"><TimeLeftView timeleft={timeLeft}></TimeLeftView></td>
      </tr>
    )
  })

  return (
    <div key={props.name}>
      <h5>{props.name}</h5>
      <table className="styled-table">
        <thead>
          <tr>
            <td className="td-land">Land</td>
            <td className="td-equip">Equip</td>
            <td className="td-timeleft">Timeleft</td>
          </tr>
        </thead>
        <tbody>
          { assets }
        </tbody>
      </table>
    </div>
  );
};

const StakedLandView: React.FunctionComponent<GameProps> = props => {
  const contract = props.game as XpansionContract;
  const staked = contract.stakedLands.map((land)=> {
    const timeLeft = contract.getTimeLeftForStakedLand(land);
    const stackBalance = getCoin(land.stake_balance);
    const availBalance = getCoin(land.avai_balance);

    if (stackBalance.value === 0) return (
        <tr key={land.land_id}>
        <td className="td-land"></td>
        <td className="td-timeleft"></td>
      </tr>
    );
    return (
      <tr key={land.land_id}>
        <td className="td-land">{land.land_id} <strong>{ availBalance.value }</strong> / <strong>{ stackBalance.value }</strong> XPS</td>
        <td className="td-timeleft"><TimeLeftView timeleft={timeLeft}></TimeLeftView></td>
      </tr>
    )
  });
  return (
    <table className="styled-table">
      <thead>
        <tr>
          <td className="td-land">Land</td>
          <td className="td-timeleft">Timeleft</td>
        </tr>
      </thead>
      <tbody>
        { staked }
      </tbody>
    </table>
  )
}

const XPBotView: React.FunctionComponent<GameProps> = props => {

  const contract = props.game as XpansionContract;

  return (
    <div className="xland table-content">      
      <LandTableView game={props.game} assets={ contract.ownAssets } name="Own"></LandTableView>
      <LandTableView game={props.game} assets={ contract.rentAssets } name="Rent"></LandTableView>
      <h5>Staked</h5>
      <StakedLandView game={props.game}></StakedLandView>
    </div>
  )
}

export default XPBotView;