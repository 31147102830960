import { AnnotationsMap, makeObservable, observable, runInAction } from "mobx";
import AtomicAssetClient, { AtomicAsset } from "../atomic/AtomicAssetClient";
import EOSClient, { TableRequest } from "../RPCClient";
import { RpcError} from 'eosjs';

export interface HarvestAsset {
  id: string,
  timeLeft: number,
  label: string,
  collectionName: string
}

export interface TransactionAction {
  game: GameContract,
  account: string,
  name: string,
  authorization: [
    {
      actor: string,
      permission: string,
    },
  ],
  data: any
}

export interface TokenBalance {
  symbol: string,
  value: number
}

export interface TokenHistory {
  tokens?: TokenBalance[]
  updated_at?: number,
}

export interface GameHistory {
  tokens?: TokenHistory[]
  updated_at?: number,
}

export default class GameContract {

  static NAME = '';
  static COLLECTION_NAME = '';
  protected _name: string = '';
  protected _contractName: string = '';
  protected _tokenContract: string = '';
  protected _collectionName: string = '';
  protected _defaultSymbol: string = '';
  protected _tokens: Map<string, TokenBalance>;
  protected _lastUpdateAt: Date;
  protected _selectedForBot: boolean;
  protected _client:EOSClient;
  protected _history:GameHistory;
  protected _buyTokenCoolDown:number;
  protected _lastBuyToken:Date;
  protected _atomicAssets: Map<string, AtomicAsset>;

  constructor(client:EOSClient) {
    // console.log('[GameContract] constructor');
    this._name = '';
    this._contractName = '';
    this._tokenContract = '';
    this._defaultSymbol = '';
    this._selectedForBot = false;
    this._tokens = new Map();
    this._lastUpdateAt = new Date();
    this._client = client;
    this._history = {};
    this._buyTokenCoolDown = 120;
    this._lastBuyToken = new Date(new Date().getTime() - 180 * 1000);
    this._atomicAssets = new Map();
    makeObservable(this, {
      _lastUpdateAt: observable,
      _selectedForBot: observable,
    } as AnnotationsMap<this, string>);
  }

  protected saveHistory() {
    const dt = Math.floor(new Date().getTime() / 1000) - this._history.updated_at!;
    if (dt > 3600) {
      // console.log(`save histoy for game ${this._name}`);
      const now = Math.floor(new Date().getTime() / 1000);
      this._history.updated_at = now;
      // max history 
      if (this._history.tokens?.length! >= 25) {
        this._history.tokens?.shift();
      }
      this._history.tokens?.push({
        updated_at: now,
        tokens:Array.from(this.tokens.values())
      });
      localStorage.setItem(this.historyKey, JSON.stringify(this._history));  
    }
  }

  protected loadHistory() {
    this._history.updated_at = 0;
    this._history.tokens = [];
    if (localStorage.getItem(this.historyKey)) {
      this._history = JSON.parse(localStorage.getItem(this.historyKey)!);
      // console.log('found history ', this._history);
    }
  }

  protected get historyKey() {
    return `history_${this._name}`;
  }

  public getRewardRate(symbol:string) {
    for (let i = 0; i < this._history.tokens?.length!; i++) {

    }
  }

  public updated() {
    // check history balance 
    this.saveHistory();

    runInAction(() => {
      this._lastUpdateAt = new Date();
    })
  }

  public async loadBalances(symbol: string = '') {
    // farmingtoken   SEST
    // eosio.token    WAX
    // toc.century    TOCIUM
    // gciogamemint   GCM
    // simpleassets   DIESEL 
    // alien.worlds   TLM 
    // farmerstoken   FWF, FWG, FWW
    try {
      let tokens: string[] = [];
      if (symbol !== '') {
        tokens = await this._client.rpc.get_currency_balance(this._tokenContract, this._client.userAccount, symbol);
      }
      else {
        tokens = await this._client.rpc.get_currency_balance(this._tokenContract, this._client.userAccount);
      }
      //console.log(tokens);
      tokens.forEach((t) => {
        const a = t.split(' ');
        this._tokens.set(a[1], { symbol: a[1], value: parseFloat(a[0]) });
        // this._tokens[a[1]] = { symbol: a[1], value: parseFloat(a[0]) }
      })
    }
    catch(e) {
      console.error(e);
    }
  }

  public get client() {
    return this._client;
  }

  public get isSelectedForBot(): boolean {
    return this._selectedForBot;
  }
  
  public set selectedForBot(value: boolean) {
    runInAction(()=> {
      this._selectedForBot = value;
    });
  }

  public get lastUpdateAt(): Date {
    return this._lastUpdateAt;
  }

  public get lastUpdatedAtString(): string {
    return this._lastUpdateAt.toLocaleTimeString();
  }

  public get name(): string {
    return this._name;
  }
  public set name(value: string) {
    this._name = value;
  }

  public get tokens(): TokenBalance[] {
    return Array.from(this._tokens.values());
  }

  public getBalance(symbol:string) {
    if (this._tokens.has(symbol) !== undefined) {
      return this._tokens.get(symbol)!.value;
    }
    return 0;
  }

  public async loadAssets(): Promise<GameContract> {
    console.log(`Start loading asset for game ${this.name}`);
    this.loadHistory();
    await this.loadBalances();
    this.updated();
    return this;
  }

  public async updateAssets(): Promise<GameContract> {
    console.log(`Start updateAssets asset for game ${this.name}`);
    await this.loadBalances();
    this.updated();
    return this;
  }

  public async listHarvest(): Promise<HarvestAsset[]> {
    return [];
  }

  public async autoActions(): Promise<TransactionAction[]> {
    return [];
  }

  public createGameActions(account: string, actor: string, actionName: string, data: any): TransactionAction {
    return {
      game: this,
      account: account,
      name: actionName,
      authorization: [
        {
          actor: actor,
          permission: "active",
        },
      ],
      data: data
    }
  }

  public async finalizeAction(action: any) {
    console.log('finalizeAction');
  }

  public async runTransaction(action: any) {
    const client = this._client;
    // console.warn(`---------------------- runTransaction ${action.name} ------------------------------`);
    const result = await client.api!.transact(
      {
        actions: [
          action
        ],
      },
      {
        blocksBehind: 3,
        expireSeconds: 1200,
      }
    );
    return result;      
  }

  protected async requestTable(request:TableRequest) {
    try {
      const results = await this._client.rpc.get_table_rows(request);
      return results;
    }
    catch (e) {
      console.error(e);
    }
  }

  protected canbuyToken() {
    const dt = (new Date().getTime() - this._lastBuyToken.getTime()) / 1000;
    if (dt > this._buyTokenCoolDown) {
      this._lastBuyToken = new Date();        
      return true;
    }
    console.warn(`Impossible to buy now wait for cooldown dt: ${dt}`);
    return false;
  }

  protected async loadAtomicAssets() {
    const client = new AtomicAssetClient();
    const assets = await client.loadAssets({
      collection_name: this._collectionName,
      owner: this._client.userAccount
    })
    assets.forEach((a) => {
      this._atomicAssets.set(a.asset_id, a);
    })
    
    return assets;
  }

  public getAtomicAssetBySchema(schema:string) {
    const assets = Array.from(this._atomicAssets.values());
    const result: AtomicAsset[] = [];
    assets.forEach((a)=> {
      if (a.schema?.schema_name === schema) {
        result.push(a);
      }
    })

    return result;
  }

  public handleRpcError(action:TransactionAction, error:RpcError): TransactionAction|null {
    console.error(`${this._name} get error = ${error.message}`);
    return action;
  }

  public createBuyTokenAction(contract: string, from: string, to: string, quantity: string, memo: string) {
    if (!this.canbuyToken()) {
      return null;
    }
    const action = this.createGameActions(
      contract,
      this._client.userAccount, 
      'transfer',
      {
        from: from,
        to: to,
        quantity: quantity,
        memo: memo
      }
    );
    return action;
  }

 public createLockedTokenAction(action:TransactionAction) {
    if (!this.canbuyToken()) {
        return null;
    }
    return action;
 }
}