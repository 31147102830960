
import { observable, makeObservable, AnnotationsMap, runInAction } from "mobx";
import Asset from './atomic/Asset';
import AtomicAssetContract from './atomic/AtomicAssetContract';
import FarmingTaleContract from '../games/farmingtales/model/FarmingTaleContract';
import GameContract, { TokenBalance } from './contract/GameContract';
import FarmersWorldContract from '../games/farmersworld/model/FarmersWorldcontract';
import CenturyTrainContract from '../games/centurytrain/model/CenturyTrainContract';
import AlienWorldsContract from '../games/alienworlds/model/AlienWorldsContract';
import NftPandaContract from '../games/nftpandawofg/model/NftPandaContract';
import EOSClient, { WaxClient } from './RPCClient';
import { GetAccountResult } from 'eosjs/dist/eosjs-rpc-interfaces';
import AnimalWorldContract from "../games/animalworld/model/AnimalWorldContract";
import XpansionContract from "../games/xpansion/model/XpansionContract";
import GameBotStore from "./GameBotStore";
import GameBot from "./GameBot";
import OfficeLandContract from "../games/oficeland/model/OfficeLandContract";
import CrittercraftContract from "../games/crittercraft/model/CrittercraftContract";
import Settings from "./Settings";

const SELECTED_GAMES = 'selected_games';

export default class UserStore {
    private _client: EOSClient;
    private _logged: boolean = false;
    private _authorized: boolean = false;
    private _assets: Asset[] = [];
    private _botStore: GameBotStore;
    private _selectedGames: Record<string, boolean>;
    private _account: GetAccountResult | undefined;
    private _mainLoopId: NodeJS.Timeout | undefined;
    private _settings: Settings;
    private _balance:TokenBalance|undefined;
    static _instance: UserStore | undefined;


    public static getInstance(): UserStore {
        if (UserStore._instance === undefined) {
            UserStore._instance = new UserStore();
        }
        return UserStore._instance;
    }

    public constructor() {
        // console.log('UserConsructor');

        this._client = new WaxClient();

        this._settings = new Settings();
        // console.log('Start wax engine');

        this._selectedGames = {};
        if (localStorage.getItem(SELECTED_GAMES)) {
            this._selectedGames = JSON.parse(localStorage.getItem(SELECTED_GAMES)!);
        }

        const games = [
            new FarmersWorldContract(this._client),
            new FarmingTaleContract(this._client),
            new CenturyTrainContract(this._client),
            new AlienWorldsContract(this._client),
            new NftPandaContract(this._client),
            new AnimalWorldContract(this._client),
            new XpansionContract(this._client),
            new OfficeLandContract(this._client),
            new CrittercraftContract(this._client),
        ];

        this._botStore = new GameBotStore(this._client);

        games.forEach((game) => {

            if (this._selectedGames[game.name] !== undefined) {
                const selected = this._selectedGames[game.name];
                game.selectedForBot = selected;
            }
            const b = new GameBot(game);
            this._botStore.addBot(b);
        })


        makeObservable(this, {
            _client: observable,
            _logged: observable,
            _authorized: observable,
            _assets: observable,
            _account: observable
        } as AnnotationsMap<this, string>);

    }

    public get settings(): Settings {
        return this._settings;
    }

    public get botStore(): GameBotStore {
        return this._botStore;
    }

    public async startWatch() {
        console.log('-------- Start Watch -------------');
        if (this._mainLoopId) {
            this.stopWatch();
        }
        this._mainLoopId = setInterval(async () => {
            // console.log(`reload ${this.client.userAccount} account`);
            try {
                await this.loadAccount();
            }
            catch (e) {
                console.error(e);
            }
        }, 15 * 1000)
    }

    public async stopWatch() {
        if (this._mainLoopId) {
            clearInterval(this._mainLoopId);
        }
    }

    public async startSelectedBots() {
        await this._botStore.startSelectedBots();
    }

    public async stopSelectedBots() {
        await this._botStore.stopSelectedBots();
    }

    public get userAccount(): string {
        if (this.isLogged()) {
            return this._client.userAccount;
        }
        return '';
    }

    public get client(): EOSClient {
        return this._client;
    }

    public isLogged() {
        return this._logged && this._authorized;
    }

    public async logout() {
        console.log('logout');
        runInAction(() => {
            this._logged = false;
        })
    }

    public async login(account: string = '') {
        try {
            if (account === '') {
                await this._client.login();
                const isAuthorized = await this.validateUser(this._client);
                if (!isAuthorized) {
                    console.error(`${this._client.userAccount} is not authorized`);
                }
                await this.loadAssets();
                await this.loadAccount();
                await this.loadBalances('WAX');
                this.startWatch();
                runInAction(() => {
                    this._logged = true;
                    this._authorized = isAuthorized;
                })
            }
            else {
                console.log(`Use simulation login ${account}`);
            }
        } catch (e) {
            console.log(e);
        }
    }

    public switchSelected(game: GameContract, selected: boolean) {
        this._selectedGames[game.name] = selected;
        game.selectedForBot = selected;
        localStorage.setItem(SELECTED_GAMES, JSON.stringify(this._selectedGames));
    }

    protected async validateUser(client: EOSClient) {
        const authorizedAccounts = ['hboiy.wam', 'qwnho.wam'];
        if (authorizedAccounts.indexOf(client.userAccount) !== -1) return true;
        return false;
    }

    protected async loadAssets() {
        const contract = new AtomicAssetContract(this._client);
        this._assets = await contract.loadAssets();
    }

    public get balance():TokenBalance|undefined {
        return this._balance;
    }

    protected async loadBalances(symbol: string = '') {
        const contracts: Record<string, string> = {
            'SEST': 'farmingtoken',
            'WAX': 'eosio.token',
            'TOCIUM': 'toc.century',
            'GCM': 'gciogamemint',
            'DIESEL': 'simpleassets',
            'TLM': 'alien.worlds',
            'FWW': 'farmerstoken',
            'FWF': 'farmerstoken',
            'FWG': 'farmerstoken',
        };
        if (contracts[symbol] === undefined) return null;

        try {
            let tokens: string[] = [];
            tokens = await this._client.rpc.get_currency_balance(contracts[symbol], this._client.userAccount);
            // console.log(tokens);
            tokens.forEach((t) => {
                const a = t.split(' ');
                this._balance = { symbol: a[1], value: parseFloat(a[0]) }
            })
        }
        catch (e) {
            console.error(e);
        }
    }
    /*
  
    */
    protected async loadAccount() {
        try {
            const account = await this._client.rpc.get_account(this._client.userAccount);
            runInAction(() => {
                this._account = account;
            })
            /*
            const actions = await this._client.rpc.history_get_actions(this._client.userAccount, -1, -100);
            console.log(actions);
            */
        }
        catch (e) {
            console.error(e);
        }
        return this.account;
    }

    public get account() {
        return this._account;
    }

    public initGame(game: FarmingTaleContract) {
        game.loadAssets();
    }

    public getAssetsByCollection(name: string): Asset[] {
        const results: Asset[] = [];
        this._assets.forEach((a) => {
            if (a.colectionName === name) results.push(a);
        })
        return results;
    }

    public getCPUUsagePercent(base100 = false) {
        if (this._account) {
            const used = this.account?.cpu_limit.used!;
            const max = this.account?.cpu_limit.max!;
            if (base100) return Math.floor(used * 10000 / max) / 100;
            return used / max;
        }
    }

    public getResouceUsagePercent(type: string, base100 = false) {
        if (this._account) {
            let used = 0;
            let max = 10000000;
            switch (type) {
                case 'cpu':
                    used = this.account?.cpu_limit.used!;
                    max = this.account?.cpu_limit.max!;
                    break;
                case 'net':
                    used = this.account?.net_limit.used!;
                    max = this.account?.net_limit.max!;
                    break;
            }
            if (base100) return Math.floor(used * 10000 / max) / 100;
            return used / max;
        }
    }

    public getPctNET() {
        if (this._account) {
            const used = this.account?.net_limit.used!;
            const max = this.account?.net_limit.max!;
            return used / max;
        }
    }

}

