import AtomicAssetClient, { AtomicAsset, AtomicTemplate } from "../../../model/atomic/AtomicAssetClient";
import GameContract, { TransactionAction } from "../../../model/contract/GameContract";
import EOSClient, { prepareTableRequest } from "../../../model/RPCClient";
import { getCoin } from "../../../model/Tools";
import { RpcError} from 'eosjs';

/**
 * https://wax.bloks.io/account/officegameio
 * 
 * regis
 * https://wax.bloks.io/transaction/b85dea1291f8846498b9fec23cc79b6056d583c4c385d867e55080226dba2c1b
 * 
 * regis (regsiter)
 * staff: qwnho.wam
 * staff_name: rubijn
 * https://wax.bloks.io/transaction/b85dea1291f8846498b9fec23cc79b6056d583c4c385d867e55080226dba2c1b
 * 
 * applyps (assign worker to slot)  
 * asset_id: 1099583168693
 * player: qwnho.wam
 * slot_id: 0
 * https://wax.bloks.io/transaction/ec5a5184b375512bbea020f709a885c64b3b9b31332d70db829d7730cb82badb
 * 
 * assigntask (assign task to slot)
 * asset_id: 1099583168693
 * player: qwnho.wam
 * task_id: 2
 * https://wax.bloks.io/transaction/2a2c18c2c8ad129d789114f6787aa2fd91c8571d6b304f297c5a57881a17facf
 * 
 * taskfinished
 * player: m22xs.wam
 * taskassign_id: 7180
 * https://wax.bloks.io/transaction/f1f3512c9a02b7f13ed2cde18105cb1f55d1a49b5a278548ce4fb923acc27137
 * 
 * officegameio - claimreward
 * finish_id: 533230
* player: qwnho.wam
 */

export interface Resume {
    active: number,
    staff: string,
    staff_id: number,
    staff_image: string,
    staff_name: string,
    staff_position: number,
    staff_rank: number
}

export interface Space {
    asset_ids: string | number[],
    max_slot: number,
    player: string,
}

export interface TaskProperties {
    ranks: string[],
    task_data: string,
    task_diff: string,
    task_id: number,
    task_name: string,
    task_reward: string,
    task_time: number,
    task_type: number,
}

export interface TaskAsign {
    asset_id: string,
    item_used: number,
    player: string,
    task_end: number,
    task_id: number,
    task_start: number,
    taskassign_id: number,
}

export interface WaitClaimTask {
    finish_id: number,
    finish_time: number,
    reward: string,
    task_id: number,
}

export interface TaskFinished extends WaitClaimTask {
    asset_id: string,
    player: string,
}

export interface StaffAsset extends AtomicAsset {
    data: {
        name: string,
        rarity: string,
        type: string
    }
}

export interface StaffTemplate extends AtomicTemplate {
    immutable_data: {
        name: string,
        rarity: string,
        type: string
    }
}

export interface StaffConfig {
    man_hour: number,
    time_reduce: number,
}

export interface AssetState {
    asset_id: string,
    owner: string,
    template_id: string,
    status: number,
    asset_data: {
        decrease_sr: string,
        increase_time: number,
        match: number,
    }
}

export default class OfficeLandContract extends GameContract {

    static NAME = 'officegameio';
    static CONTRACT_NAME = 'officegameio';
    static COLLECTION_NAME = 'officelandio'
    static TOKEN_CONTRACT = 'officetokens'

    private _resumes: Resume[] = [];
    private _publicSpaces: Space[] = [];
    private _tasksProperties: TaskProperties[] = [];
    private _tasksAssign: TaskAsign[] = [];
    // private _assets: Map<string, StaffAsset> = new Map();
    private _templates: Map<string, StaffTemplate> = new Map();
    private _assetStates:AssetState[] = [];
    private _staffConfig: Map<string, StaffConfig>;
    private _taskFinished: TaskFinished[] = [];
    private _waitClaimTasks: WaitClaimTask[] = [];
    private _claimCoolDownTimeInSeconds: number;
    private _waitForUpgrade:string[] = ['1099649615263', '1099590318999', '1099653180818'];

    // 2021-12-11 08:35 (UTC+0) -> 2021-12-14 11:32 -> 18.76 % -   1639211754 -   1639481528 = 269774
    // 2021-12-14 09:00 (UTC+0) -> 2021-12-14 11:30 -> 48.96 % -   1639472454 -   1639481528 = 9074           432000

    constructor(client: EOSClient) {
        super(client);
        this._name = OfficeLandContract.NAME;
        this._contractName = OfficeLandContract.CONTRACT_NAME;
        this._tokenContract = OfficeLandContract.TOKEN_CONTRACT;
        this._defaultSymbol = 'OCOIN';

        this._claimCoolDownTimeInSeconds = 5 * 24 * 3600;

        this._resumes = [];
        this._taskFinished = [];
        this._waitClaimTasks = [];
        this._assetStates = [];
        // this._assets = new Map();
        this._templates = new Map();
        // https://officeland.gitbook.io/office-land-white-paper/gameplay/virtual-working
        this._staffConfig = new Map<string, StaffConfig>([
            ["intern", { man_hour: 1, time_reduce: 0 }],
            ["junior", { man_hour: 1.5, time_reduce: 0.05 }],
            ["senior", { man_hour: 2, time_reduce: 0.10 }],
            ["leader", { man_hour: 2.5, time_reduce: 0.15 }],
            ["manager", { man_hour: 3, time_reduce: 0.2 }],
            ["boss", { man_hour: 5, time_reduce: 0.25 }],
        ]);
        // The finished tasks can be claimed for OCOIN. 
        // Fee will be deducted from the reward, and the initial fee rate is 50%. 
        // Fee rate will be decreased for 10% each day, and will be 0% after 5 days since the tasks are finished.

    }

    public get templates() {
        return this._templates;
    }

    public get tasksAssign() {
        return this._tasksAssign;
    }

    public get publicSpaces() {
        return this._publicSpaces;
    }

    public loadAssets = async () => {

        await this.loadResumes();

        await this.loadFinishedTasks();

        await this.loadStaffTemplates();

        await this.loadAssetStates();

        await this.loadPublicSpace();

        await this.loadTaskList();

        await this.loadTaskAssign();

        //console.log(this.findNextTask("1099590522055"));
        // console.log(this.findNextTask("1099583168693"));
        // console.log(this.getTemplateFromAsset('1099599675934')); // Ethan Leader
        // console.log(this.findNextTask("1099599675934"));

        await super.loadAssets();

        return this;
    }

    public updateAssets = async () => {

        await this.loadResumes();

        await this.loadFinishedTasks();

        await this.loadAssetStates();

        await this.loadPublicSpace();

        await this.loadTaskList();

        await this.loadTaskAssign();

        await super.updateAssets();

        return this;
    }

    public async autoActions(): Promise<TransactionAction[]> {

        const client = this._client;

        await this.updateAssets();

        const actions: TransactionAction[] = [];

        // check if claims available
        const tasksToClaim = this.getTaskToClaim();
        if (tasksToClaim.length > 0) {
            const task = tasksToClaim.pop();
            console.log(`claim task ${task?.task_id}`)
            actions.push(this.createGameActions(
                this._contractName,
                client.userAccount,
                'claim',
                {
                    finish_id: task?.finish_id,
                    player: this._client.userAccount,
                }
            ));
        }
        if (actions.length !== 0) return actions;

        // must assign task to slot
        this._publicSpaces.forEach((space) => {
            for (let asset_id of space.asset_ids) {
                // check if this asset is in slot and have a current task
                if (asset_id !== 0 && !this.isWorking(asset_id.toString())) {
                    const template = this.getTemplateFromAsset(asset_id.toString())!;

                    console.log(`${asset_id} ${template.immutable_data.name} not working let assign a task`);
                    const taskId = this.findNextTask(asset_id.toString());
                    actions.push(
                        this.createGameActions(
                            OfficeLandContract.NAME,
                            client.userAccount,
                            'assigntask',
                            {
                                player: this._client.userAccount,
                                asset_id: asset_id,
                                task_id: taskId
                            }
                        )
                    );
                }
            }
        })
        if (actions.length !== 0) return actions;

        // check if on task is finished 
        this._tasksAssign.forEach((assign) => {
            const timeLeft = this.getTimeleft(assign);
            const blockedItems: string[] = [];
            if (blockedItems.indexOf(assign.asset_id) !== -1) {
                const template = this.getTemplateFromAsset(assign.asset_id)!;
                console.warn(`standby for ${assign.asset_id} name: ${template.immutable_data.name}`);
            }
            else if (timeLeft < 0) {
                console.log(`Must finished task ${assign.taskassign_id}`);
                actions.push(
                    this.createGameActions(
                        OfficeLandContract.NAME,
                        client.userAccount,
                        'taskfinished',
                        {
                            player: this._client.userAccount,
                            taskassign_id: assign.taskassign_id
                        }
                    )
                );
            }
        });

        return actions;
    }

    public async loadBalances(symbol: string = '') {
        await super.loadBalances(symbol);
        const request = prepareTableRequest({
            code: OfficeLandContract.CONTRACT_NAME,
            scope: OfficeLandContract.CONTRACT_NAME,
            table: 'balances',
            lower_bound: this._client.userAccount,
            upper_bound: this._client.userAccount,
        });
        try {
            const result = await this.requestTable(request);
            if (result) {
                if (result!['rows'][0] !== undefined) {
                    const quantity = result!['rows'][0]['quantity'];
                    const ocoin = getCoin(quantity);
                    ocoin.symbol = 'OCOIN_G';
                    this._tokens.set('OCOIN_G', ocoin);
                }
                else {
                    this._tokens.set('OCOIN_G', { value: 0, symbol: 'OCOIN_G' });
                }
            }
        }
        catch (e) {
            console.error(e);
        }
    }

    public isWaitingForUpdate(asset_id:string) {
        return this._waitForUpgrade.indexOf(asset_id) !== -1;
    }

    ///////////////////////////////////
    // utils
    ///////////////////////////////////

    protected getPctFee(task: WaitClaimTask) {
        const time = Math.floor((new Date().getTime() / 1000) - task.finish_time);
        const minutesByDay = 3600 * 24;
        let pct = 0;
        if (time > 0) {
            const nbDays = Math.floor(time / (minutesByDay));
            const dt = time - nbDays * minutesByDay;
            pct = 0.5 - nbDays * 0.1 - 0.1 * (dt / minutesByDay); // - ((time-nbDays*minutesByDay)/(minutesByDay));
        }
        return Math.max(pct, 0);
    }

    public getTaskToClaim() {
        const tasks: WaitClaimTask[] = [];
        this._waitClaimTasks.forEach((task) => {
            let pct = this.getPctFee(task);
            if (pct === 0) {
                tasks.push(task);
            }
        });
        return tasks;
    }

    public get rewards() {
        let value = 0;
        this._waitClaimTasks.forEach((task) => {
            value += parseFloat(task.reward);
        });
        return value;
    }

    public get instantRewards() {
        let value = 0;
        this._waitClaimTasks.forEach((task) => {
            // console.log(new Date(task.finish_time*1000).toUTCString())
            const pct = this.getPctFee(task);
            value += parseFloat(task.reward) * (1 - pct);
        });
        return value;
    }
    
    public getTimeleft(assign: TaskAsign): number {
        const t = assign.task_end - new Date().getTime() / 1000;
        return Math.round(t);
    }
    
    public getAssetState(asset_id: string): AssetState|null {
        for (let state of this._assetStates) {
            if (state.asset_id === asset_id) return state;
        }
        return null;
    }

    public getDecreaseRate(asset_id: string): number {
        const state = this.getAssetState(asset_id);
        if (state) {
            return parseFloat(state.asset_data.decrease_sr);
        }
        return 100.0;
    }

    protected isWorking(asset_id: string): boolean {
        
        if (this.isWaitingForUpdate(asset_id)) {
            console.warn(`Force Working status for ${asset_id} because need to update`);
            return true;
        }
        for (let assign of this._tasksAssign) {
            if (assign.asset_id === asset_id) return true;
        }        
        return false;
    }

    protected getSleepTask() {
        for (let task of this._tasksProperties) {
            if (task.task_id === 7) return task;
        }
        return null;
    }

    public getTemplateFromAsset(asset_id:string): StaffTemplate|undefined {        
        for (let state of this._assetStates) {
            if (state.asset_id === asset_id) {
                return this._templates.get(state.template_id.toString());
            }
        }
        return undefined;
    }

    protected findNextTask(asset_id: string): number {
        // Calculation: Reward = Task Hours  x ( Man hours - Job Difficulty ) 
        const template = this.getTemplateFromAsset(asset_id)!;
        const rarity = template.immutable_data.rarity;
        const staffConfig = this._staffConfig.get(rarity)!;
        // console.log(staffConfig);
        let rewardByHourMax = 0;
        let selected: TaskProperties | null = null;
        const pctDecrease = this.getDecreaseRate(asset_id);
        if (pctDecrease >= 30.0) {
            console.warn(`${asset_id} must sleep !`);
            selected = this.getSleepTask()!;
        }
        else {
            for (let task of this._tasksProperties) {
                // console.log(task, task.task_reward);
                if (task.ranks.indexOf(rarity) !== -1) {
                    // skip sleep 
                    if (task.task_id === 7) continue;
                    const finalTime = task.task_time * (1 - staffConfig?.time_reduce);
                    let reward = task.task_time * (staffConfig.man_hour - parseFloat(task.task_diff));
                    const rewardByHour = reward / finalTime;
                    // console.log(`${task.task_name} id:${task.task_id} time:${task.task_time} reward:${task.task_reward} diff:${task.task_diff} reward:${reward} rewardByHour:${rewardByHour}`);
                    if (rewardByHour > rewardByHourMax) {
                        rewardByHourMax = rewardByHour;
                        selected = task;
                    }
                }
            }
        }

        return selected?.task_id!;
    }


    ///////////////////////////////////
    // loads 
    ///////////////////////////////////

    protected async loadStaffTemplates() {
        try {
            const client = new AtomicAssetClient();
            const result = await client.loadTemplates({
                collection_name: this._collectionName,
                schema_name: 'staffs',
                page: 1,
                limit: 100,
            })
            result.forEach((row) => {
                this._templates.set(row.template_id, row as StaffTemplate);
            })
        }
        catch (e) {
            console.error(e);
        }
    }


    protected async loadResumes() {
        const client = this._client;
        try {
            const results = await client.rpc.get_table_rows({
                json: true,
                code: OfficeLandContract.CONTRACT_NAME,
                scope: OfficeLandContract.CONTRACT_NAME,
                table: 'resumes',
                lower_bound: client.userAccount,
                upper_bound: client.userAccount,
                limit: 1,
                reverse: false,
                show_payer: false
            });
            const rows = results['rows'];
            this._resumes = rows;
            /*
            console.log('----------resumes ------------');
            console.log(this._resumes);
            */
        }
        catch (e) {
            console.error(e);
        }
    }

    protected async loadAssetStates() {
        const request = prepareTableRequest({
            code: this._contractName,
            scope: this._contractName,
            table: 'asset.state',
            index_position: 2,
            key_type: 'name',
            lower_bound: this._client.userAccount,
            upper_bound: this._client.userAccount,
        })
        try {
            const result = await this.requestTable(request);
            const rows = result!['rows'];
            this._assetStates = rows;
            /*
            console.log('---------- assetStates ------------');
            console.log(this._assetStates);
            */
        }
        catch (e) {
            console.error(e);
        }
    }

    protected async loadPublicSpace() {
        /*
          asset_ids: ["1099583168693", 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,…]
          max_slot: 5
          player: "qwnho.wam"
        */
        const client = this._client;
        try {
            const tableName = 'publicslots'; // 'publicspace'
            const results = await client.rpc.get_table_rows({
                json: true,
                code: OfficeLandContract.CONTRACT_NAME,
                scope: OfficeLandContract.CONTRACT_NAME,
                table: tableName,
                lower_bound: client.userAccount,
                upper_bound: client.userAccount,
                limit: 100,
                reverse: false,
                show_payer: false
            });
            const rows = results['rows'];
            this._publicSpaces = rows;
            /*
            console.log('---------- publicSpaces ------------');
            console.log(this._publicSpaces);
            */
            // "1099599544269"
            /*
            const assetToLoad = [];
            for (let space of this._publicSpaces) {
                for (let asset_id of space.asset_ids) {
                    if (asset_id !== 0 && !this._assets.has(asset_id.toString())) {
                        assetToLoad.push(asset_id.toString());
                    }
                }
            }
            console.log(assetToLoad);

            if (assetToLoad.length !== 0) {
                const atomic = new AtomicAssetClient();
                const result = await atomic.loadAssets({
                    ids: assetToLoad.join(',')
                })
                if (result) {
                    result.forEach((asset) => {
                        this._assets.set(asset.asset_id, asset as StaffAsset);
                    })
                }
            }
            */

        }
        catch (e) {
            console.error(e);
        }
    }

    protected async loadFinishedTasks() {
        const request = prepareTableRequest({
            code: OfficeLandContract.CONTRACT_NAME,
            scope: OfficeLandContract.CONTRACT_NAME,
            table: 'waitclaims',
            lower_bound: this._client.userAccount,
            upper_bound: this._client.userAccount,
        })
        try {
            const results = await this._client.rpc.get_table_rows(request);
            const rows = results['rows'];
            this._waitClaimTasks = rows[0]['claim_datas'];
            /*
            console.log('---------- waitClaimTasks ------------');
            console.log(this._waitClaimTasks);
            */
        }
        catch (e) {
            console.error(e);
        }

    }

    protected async loadTaskList() {
        const client = this._client;
        try {
            const results = await client.rpc.get_table_rows({
                json: true,
                code: OfficeLandContract.CONTRACT_NAME,
                scope: OfficeLandContract.CONTRACT_NAME,
                table: 'tasklist',
                limit: 100,
                reverse: false,
                show_payer: false
            });
            const rows = results['rows'];
            this._tasksProperties = rows;
            /*
            console.log('---------- tasksProperties ------------');
            console.log(this._tasksProperties);
            */
        }
        catch (e) {
            console.error(e);
        }
    }

    public handleRpcError(action:TransactionAction, error:RpcError): TransactionAction|null {
        console.error(`${this._name} get error = ${error.message}`);
        return null;
    }

    public async loadTaskAssign() {
        const client = this._client;
        try {
            const request1 = prepareTableRequest({
                code: OfficeLandContract.CONTRACT_NAME,
                scope: OfficeLandContract.CONTRACT_NAME,
                index_position: 3,
                key_type: 'name',
                table: 'taskassign',
                lower_bound: client.userAccount,
                upper_bound: client.userAccount,

            })
            const results = await client.rpc.get_table_rows(request1);
            const rows = results['rows'];
            this._tasksAssign = rows;

            const request2 = prepareTableRequest({
                code: OfficeLandContract.CONTRACT_NAME,
                scope: OfficeLandContract.CONTRACT_NAME,
                table: 'assigntasks',
                lower_bound: client.userAccount,
                upper_bound: client.userAccount,
            });
            const results2 = await client.rpc.get_table_rows(request2);
            const rows2 = results2['rows'];
            /*
            console.log('---------- Assign tasks ------------');
            console.log(rows2[0]['datas']);
            */
            const newTasks:any = rows2[0]['datas'];
            for (let t of newTasks) {
                this._tasksAssign.push(t);
            }

            // console.log('---------- tasksAssign ------------');
            // console.log(this._tasksAssign);

        }
        catch (e) {
            console.error(e);
        }
    }



}


