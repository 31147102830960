import React from "react";
import { GameProps } from "../../../views/components/GameView";
import AlienWorldsContract from "../model/AlienWorldsContract";
import TimeLeftView from "../../../views/components/TimeLeftView";

const AWBotView: React.FunctionComponent<GameProps> = props => {
  const aw = props.game as AlienWorldsContract;
  const timeLeft = aw.getTimeLeft(aw.miner.last_mine);
  return (
    <div className="table-content">      
      <table className="styled-table">
        <tbody>
          <tr>
            <td>Mine</td>
            <td className="td-timeleft"><TimeLeftView timeleft={timeLeft}></TimeLeftView></td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export default AWBotView;


