import React from "react";
import { GameProps } from "../../../views/components/GameView";
import TimeLeftView from "../../../views/components/TimeLeftView";
import CrittercraftContract from "../model/CrittercraftContract";

const MusicView: React.FunctionComponent<GameProps> = props => {
  const contract = props.game as CrittercraftContract;

  const claims = contract.claims.map((claim) => {
    return (
      <tr key={claim.asset_id}>
        <td className="td-name">{ claim.music?.data.name }</td>
        <td className="td-timeleft"><TimeLeftView timeleft={contract.getTimeleft(claim)}></TimeLeftView></td>
      </tr>
    )
  });

  return (
    <div className="music table-container">      
      <table className="styled-table">
        <thead>
          <tr>
            <td className="td-name">Name</td>
            <td className="td-timeleft">TimeLeft</td>
          </tr>
        </thead>
        <tbody>
          { claims }
        </tbody>
      </table>
    </div>
  )
}

const CrittercraftBotView: React.FunctionComponent<GameProps> = props => {
  const contract = props.game as CrittercraftContract;
  return (
    <div>
      <MusicView game={contract}></MusicView>
    </div>
  )
}

export default CrittercraftBotView;